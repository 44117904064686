import React, { useEffect } from "react";
import CourseService from "../../services/CourseService";
import { useParams, useHistory, Link } from "react-router-dom";
import jwt_decode from "jwt-decode";

export default function PracticalExam(props) {
  // useEffect(() => {
  //   const token = localStorage.getItem("token");
  //   console.log(jwt_decode(token));
  //   // CourseService.getExamPracticalQuestions(examId).then((res) => {});
  //   // if (!token) {
  //   //   props.history.push("/login");
  //   // } else {
  //   //   const userId = jwt_decode(localStorage.getItem("token")).id;
  //   //   // const examId = "6262413737943525bc559024";
  //   //   CourseService.getExamPracticalQuestions(examId, userId).then((res) => {
  //   //   });
  //   // }
  // }, []);

  return (
    <div className="container my-5">
      <div className="container mt-2">
        <h2 className="pt-4">MCQ Exam</h2>
        <div class="accordion" id="accordionExampleMCQ">
          <div class="accordion-item border border-primary mb-3">
            <h2 class="accordion-header" id="headingOneMCQ">
              <button
                class="accordion-button"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseMCQ"
                aria-expanded="true"
                aria-controls="collapseOne"
              >
                Steps to appear the MCQ Exam
              </button>
            </h2>
            <div
              id="collapseMCQ"
              class="accordion-collapse collapse show"
              aria-labelledby="headingOneMCQ"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body">
                <ul>
                  <li>
                    <p>
                      Go to Profile {'->'} MCQExam {'->'} Enter ExamCode and submit. One link will be created to start the exam.
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container mt-2">
        <h2 className="pt-4">Practical Exam</h2>
        <div class="accordion" id="accordionExample">
          <div class="accordion-item border border-primary mb-3">
            <h2 class="accordion-header" id="headingOne">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseOne"
                aria-expanded="false"
                aria-controls="collapseOne"
              >
                Windows Users
              </button>
            </h2>
            <div
              id="collapseOne"
              class="accordion-collapse collapse"
              aria-labelledby="headingOne"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body">
                <h2>Practical Exam By Automation Tool</h2>
                <ul>
                  <li>
                    <p>
                      access the network location <span className="bg-secondary-subtle px-1">\\RXDBSERVER2\trainee-materials\exe-new\</span>, copy setup-win and exam-win files in your system and Install setups in your system in a specified order.
                    </p>
                    <ol>
                      <li>
                        run setup-win once. do not close the window. it will auto close. 
                      </li>
                      <li>
                        Appear the exam: run exam-win  
                      </li>
                    </ol>
                  </li>
                  <li className="mt-2">
                    Enter exam code which is provided on your email.
                  </li>
                  <li>
                    Put Source code in the Source Folder only.
                  </li>
                  <li>
                    To run the test cases you need to put the application URL. 
                  </li>
                </ul>
              </div>
              <div class="accordion-body">
                <h2>Practical Exam Manually </h2>
                <ul>
                  <li>
                    <p>
                      access the network location <span className="bg-secondary-subtle px-1">\\RXDBSERVER2\trainee-materials\exe-new\backup</span>, copy setup-win and exam-win files in your system and Install setups in your system in a specified order.
                    </p>
                    <ol>
                      <li>
                        run setup-win once. do not close the window. it will auto close. 
                      </li>
                      <li>
                        Appear the exam: run exam-win  
                      </li>
                    </ol>
                  </li>
                  <li className="mt-2">
                    Enter exam code which is provided on your email. And Exam Question will appear.
                  </li>
                  <li>
                    Put Source code in the Source Folder only.
                  </li>
                </ul>
              </div>
              <ul>
                <li className="fs-3 ms-3">Notes
                  <ol className="fs-5 ms-3">
                    <li>
                      Do not press Ctrl + C in the exam-win terminal.
                    </li>
                    <li>
                      Do not lock your system during the exam.
                    </li>
                  </ol>
                </li>
              </ul>
            </div>
          </div>
          <div class="accordion-item border border-primary mb-3">
            <h2 class="accordion-header" id="headingTwo">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseTwo"
                aria-expanded="false"
                aria-controls="collapseTwo"
              >
                Linux Users
              </button>
            </h2>
            <div
              id="collapseTwo"
              class="accordion-collapse collapse"
              aria-labelledby="headingTwo"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body">
                <h2>Practical Exam By Automation Tool</h2>
                <ul>
                  <li>
                    <p>
                      access the network location <span className="bg-secondary-subtle px-1">\\RXDBSERVER2\trainee-materials\exe-new\</span>, copy setup-linux and exam-linux files in your system and follow the below steps.
                    </p>
                    <ol>
                      <li>
                        Run the installation for setup-linux first. The setup will automatically close after it finishes, so do not close the terminal window manually. Open a terminal in the directory where you copied the files, and run:
                        <code>
                          <div>
                            chmod +x setup-linux
                          </div>
                          <div>
                            ./setup-linux
                          </div>
                        </code>
                      </li>
                      <li>
                        After the setup-linux has completed, run exam-linux:
                        <code>
                          <div>
                            chmod +x exam-linux
                          </div>
                          <div>
                            ./exam-linux
                          </div>
                        </code>
                      </li>
                      <li>
                        Enter the exam code provided in your email when prompted during the exam setup.
                      </li>
                      <li>
                        To run the test cases, you will need to provide the application URL when prompted.
                      </li>
                    </ol>
                  </li>
                </ul>
              </div>
              <div class="accordion-body">
                <h2>Practical Exam Manually </h2>
                <ul>
                  <li>
                    <p>
                      access the network location <span className="bg-secondary-subtle px-1">\\RXDBSERVER2\trainee-materials\exe-new\backup</span>, copy setup-linux and exam-linux files in your system and follow the below steps.
                    </p>
                    <ol>
                      <li>
                        Run the installation for setup-linux first. The setup will automatically close after it finishes, so do not close the terminal window manually. Open a terminal in the directory where you copied the files, and run:
                        <code>
                          <div>
                            chmod +x setup-linux
                          </div>
                          <div>
                            ./setup-linux
                          </div>
                        </code>
                      </li>
                      <li>
                        After the setup-linux has completed, run exam-linux:
                        <code>
                          <div>
                            chmod +x exam-linux
                          </div>
                          <div>
                            ./exam-linux
                          </div>
                        </code>
                      </li>
                      <li>
                        Enter the exam code provided in your email when prompted during the exam setup.
                      </li>
                    </ol>
                  </li>
                </ul>
              </div>
              <ul>
                <li className="fs-3 ms-3">Notes
                  <ol className="fs-5 ms-3">
                    <li>
                      Run chmod +x ./setup-linux and chmod +u ./setup-linux on
                      location where .exe is downloaded.
                    </li>
                    <li>Run ./setup-linux when you are ready for exam.</li>
                    <li>
                      Put Source code in the Source Folder only.
                    </li>
                    <li>
                      Do not press Ctrl + C in the exam-linux terminal.
                    </li>
                    <li>
                      Do not lock your system during the exam.
                    </li>
                  </ol>
                </li>
              </ul>
            </div>
          </div>
          <div class="accordion-item border border-primary mb-3">
            <h2 class="accordion-header" id="headingThree">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseThree"
                aria-expanded="false"
                aria-controls="collapseThree"
              >
                MAC Users
              </button>
            </h2>
            <div
              id="collapseThree"
              class="accordion-collapse collapse"
              aria-labelledby="headingThree"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body">
                <h2>Practical Exam By Automation Tool</h2>
                <ul>
                  <li>
                    <p>
                      access the network location <span className="bg-secondary-subtle px-1">\\RXDBSERVER2\trainee-materials\exe-new\</span>, copy setup-macos and exam-macos files in your system and follow the below steps.
                    </p>
                    <ol>
                      <li>
                        Run the installation for setup-macos first. The setup will automatically close after it finishes, so do not close the terminal window manually. Open a terminal in the directory where you copied the files, and run:
                        <code>
                          <div>
                            chmod +x setup-macos
                          </div>
                          <div>
                            ./setup-macos
                          </div>
                        </code>
                      </li>
                      <li>
                        After the setup-macos has completed, run exam-macos:
                        <code>
                          <div>
                            chmod +x exam-macos
                          </div>
                          <div>
                            ./exam-macos
                          </div>
                        </code>
                      </li>
                      <li>
                        Enter the exam code provided in your email when prompted during the exam setup.
                      </li>
                      <li>
                        To run the test cases, you will need to provide the application URL when prompted.
                      </li>
                    </ol>
                  </li>
                </ul>
              </div>
              <div class="accordion-body">
                <h2>Practical Exam Manually </h2>
                <ul>
                  <li>
                    <p>
                      access the network location <span className="bg-secondary-subtle px-1">\\RXDBSERVER2\trainee-materials\exe-new\backup</span>, copy setup-macos and exam-macos files in your system and follow the below steps.
                    </p>
                    <ol>
                      <li>
                        Run the installation for setup-macos first. The setup will automatically close after it finishes, so do not close the terminal window manually. Open a terminal in the directory where you copied the files, and run:
                        <code>
                          <div>
                            chmod +x setup-macos
                          </div>
                          <div>
                            ./setup-macos
                          </div>
                        </code>
                      </li>
                      <li>
                        After the setup-macos has completed, run exam-macos:
                        <code>
                          <div>
                            chmod +x exam-macos
                          </div>
                          <div>
                            ./exam-macos
                          </div>
                        </code>
                      </li>
                      <li>
                        Enter the exam code provided in your email when prompted during the exam setup.
                      </li>
                    </ol>
                  </li>
                </ul>
              </div>
              <ul>
                <li className="fs-3 ms-3">Notes
                  <ol className="fs-5 ms-3">
                    <li>
                      Run chmod +x ./setup-macos and chmod +u ./setup-macos on location where file is downloaded.
                    </li>
                    <li>Run ./setup-macos when you are ready for exam.</li>
                    <li>
                      Put Source code in the Source Folder only.
                    </li>
                    <li>
                      Do not press Control + C in the exam-macos terminal.
                    </li>
                    <li>
                      Do not lock your system during the exam.
                    </li>
                  </ol>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
  </div>
  );
}
