import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import { BiCommentDetail } from "react-icons/bi";
import { Link, useNavigate } from "react-router-dom";
import Select from "react-select";
import HandleMessage from "../../components/HandleMessage/HandleMessages";
import Loader from '../../components/loader/Loader';
import BatchManagementServices from "../../services/BatchManagementServices";
import variable from "../../servicesvariable";
import "./ResultBatchParentNew.css";
import { FaCheck, FaPen } from "react-icons/fa";
import jwt_decode  from "jwt-decode";

export default function ResultBatchParentNew() {

     ReactHTMLTableToExcel.format = (s, c) => {
          if (c && c["table"]) {
               const html = c.table;
               const parser = new DOMParser();
               const doc = parser.parseFromString(html, "text/html");
               const rows = doc.querySelectorAll("tr");
               c.table = doc.querySelector("table").outerHTML;
          }

          return s.replace(/{(\w+)}/g, (m, p) => c[p]);
     };

     const sortingOptions = [
          {
               value: "username-asc",
               label: "username asc",
          },
          {
               value: "username-desc",
               label: "username desc",
          },
          {
               value: "result-asc",
               label: "result asc",
          },
          {
               value: "result-desc",
               label: "result desc",
          },
          {
               value: "confirmation-asc",
               label: "confirmation asc",
          },
          {
               value: "confirmation-desc",
               label: "confirmation desc",
          },
          {
               value: "skillAverage-asc",
               label: "skillAverage asc",
          },
          {
               value: "skillAverage-desc",
               label: "skillAverage desc",
          },
     ];

     const userStatusOptions = [
          {
               value: false,
               label: "Active",
          },
          {
               value: true,
               label: "inActive",
          },
     ]

     const navigate = useNavigate();
     const [loader, setLoader] = useState(false)
     const [confirmBox, setConfirmBox] = useState(false)
     const [message, setMessage] = useState({ msg: "", status: false })
     const [parentBatch, setParentBatches] = useState([]);
     const [batches, setBatches] = useState([]);
     const [batchSkills, setBatchSkills] = useState([]);
     const [batchUsers, setBatchUsers] = useState([]);
     const [batchUsersResults, setBatchUsersResults] = useState([]);
     const [resultFilterValue, setResultFilterValue] = useState({})
     const [aveargeStatus, setAveargeStatus] = useState(0)
     const [selectedSorting, setSelectedSorting] = useState(sortingOptions[0]);
     const [attendance, setAttendance] = useState([])
     const [userStatus, setUserStatus] = useState({ value: null })
     const [handleRemarks, setHandleRemarks] = useState([])
     const [rewardWeightage ,setRewardWeightage] = useState([])
     const [skillWiseWeightage, setSkillWiseWeightage] = useState([])

     useEffect(() => {
          getBatchParentsList()
          getAttendance()
     }, [])

     const handleMessage = (message, status, magtime = false) => {
          setConfirmBox(true)
          setMessage({ msg: message, status: status })
          if (magtime) {
               setTimeout(() => setConfirmBox(false), 3000)
          }
     }

     const getAttendance = async () => {
          const token = localStorage.getItem("token");
          BatchManagementServices.getAllAttendanceMis(token)
               .then((res) => {
                    if (res.data) {
                         setAttendance(res.data.data);
                    }
               })
               .catch((error) => {
                    // handleCatchError(error)
               });
     };

     const handleCatchError = (err) => {
          setLoader(false)
          if (err["response"]) {
               if (err["response"].status === 401) {
                    if (err["response"]?.data?.message === "You are not authorized to view this content") {
                         handleMessage(err["response"].data.message, false)
                    } else {
                         localStorage.removeItem("token");
                         navigate("/login");
                    }
               } else if (err["response"].status === 404) {
                    handleMessage(err["response"]?.data?.message, false, true)
               } else {
                    localStorage.removeItem("token");
                    navigate("/login");
               }
          }
     }

     const getBatchParentsList = () => {
          setLoader(true)
          BatchManagementServices.getParentBatches(localStorage.getItem("token"))
               .then((res) => {
                    if (res.data) {
                         setLoader(false)
                         const parentBatchesOptions = res.data.data.map((i) => {
                              return { value: i._id, label: i.name };
                         });
                         setParentBatches(parentBatchesOptions)
                    }
               })
               .catch((error) => {
                    clearUserResults()
                    handleCatchError(error)
               });
     }

     const clearUserResults = () => {
          setBatches([])
          setBatchSkills([]);
          setBatchUsers([])
          setBatchUsersResults([])
          setRewardWeightage([])
     }

     const getBatchsUsersResults = (values, filterChangeEventName) => {
          setLoader(true)
          const token = localStorage.getItem("token");
          BatchManagementServices.getBatchRelatedUserResults(filterChangeEventName, values, token).then((res) => {
               if (res.data) {
                    setLoader(false)
                    if (filterChangeEventName === 'parentBatch') {
                         const skills = []
                         res.data.batchNames.map(batch => {
                              const batchwiseSkills = {} 
                              batchwiseSkills["batchName"] = batch.label
                              batchwiseSkills["skills"] = []
                              batch.skills
                              .filter(reward => reward.performanceReward !== undefined)
                              .map(reward => (
                                   batchwiseSkills["skills"].push(
                                        {
                                             skillName: reward.skillName, 
                                             weighatge:reward.weightage,
                                             performanceReward: reward.performanceReward 
                                        }
                                   )
                              ))
                              skills.push(batchwiseSkills)
                         })
                         setSkillWiseWeightage(skills)
                         BatchManagementServices.getPerformanceRewardGrace(res.data.batchNames.map(batch => batch.value),token)
                         .then((resp) => {
                              const graces = resp.data.data;
                              setBatchUsersResults(res.data.userResultList
                                   .map(result => {
                                        const reward = graces.find(val => val.reward === 0 && val.userId === result.userId && val.batchId === result.batchId);
                                        const reward1 = graces.find(val => val.reward === 1 && val.userId === result.userId && val.batchId === result.batchId);
                                        const reward2 = graces.find(val => val.reward === 2 && val.userId === result.userId && val.batchId === result.batchId);
                                        const reward3 = graces.find(val => val.reward === 3 && val.userId === result.userId && val.batchId === result.batchId);
                                        
                                        return {
                                             ...result,
                                             performanceReward1Grace: reward1?.grace || 0,
                                             performanceReward2Grace: reward2?.grace || 0,
                                             performanceReward3Grace: reward3?.grace || 0,
                                             performanceGrace: reward?.grace || 0
                                        }
                                   })
                              )
                         })
                         .catch((err) => {
                              console.log(err);
                              setBatchUsersResults(res.data.userResultList
                                   .map(result =>({
                                        ...result,
                                        performanceReward1Grace: 0,
                                        performanceReward2Grace: 0,
                                        performanceReward3Grace: 0,
                                        performanceGrace: 0
                                   }))
                              )
                         })
                         BatchManagementServices.getRewardWeightage(res.data.batchNames.map(batch => batch.value), token)
                         .then(resp =>{
                              setRewardWeightage(resp.data.data)
                         })
                         .catch(err => console.log("Error occur while fetching reward weightage:", err))
                         setBatches(res.data.batchNames)
                         setBatchSkills(res.data.subCourseNames);
                         setBatchUsers(res.data.userNames)
                    }
                    else if (filterChangeEventName === 'childBatch') {
                         setBatchSkills(res.data.subCourseNames)
                         setBatchUsers(res.data.userNames)
                         BatchManagementServices.getPerformanceRewardGrace(res.data.batchNames.map(batch => batch.value),token)
                         .then((resp) => {
                              const graces = resp.data.data;
                              setBatchUsersResults(res.data.userResultList
                                   .map(result => {
                                        const reward = graces.find(val => val.reward === 0 && val.userId === result.userId && val.batchId === result.batchId);
                                        const reward1 = graces.find(val => val.reward === 1 && val.userId === result.userId && val.batchId === result.batchId);
                                        const reward2 = graces.find(val => val.reward === 2 && val.userId === result.userId && val.batchId === result.batchId);
                                        const reward3 = graces.find(val => val.reward === 3 && val.userId === result.userId && val.batchId === result.batchId);
                                        
                                        return {
                                             ...result,
                                             performanceReward1Grace: reward1?.grace || 0,
                                             performanceReward2Grace: reward2?.grace || 0,
                                             performanceReward3Grace: reward3?.grace || 0,
                                             performanceGrace: reward?.grace || 0
                                        }
                                   })
                              )
                         })
                         .catch((err) => {
                              console.log(err);
                              setBatchUsersResults(res.data.userResultList
                                   .map(result =>({
                                        ...result,
                                        performanceReward1Grace: 0,
                                        performanceReward2Grace: 0,
                                        performanceReward3Grace: 0,
                                        performanceGrace: 0
                                   }))
                              )
                         })
                    }
                    else {
                         // setBatchSkills(res.data.subCourseNames)
                         // setBatchUsers(res.data.userNames)
                         BatchManagementServices.getPerformanceRewardGrace(res.data.batchNames.map(batch => batch.value),token)
                         .then((resp) => {
                              const graces = resp.data.data;
                              setBatchUsersResults(res.data.userResultList
                                   .map(result => {
                                        const reward = graces.find(val => val.reward === 0 && val.userId === result.userId && val.batchId === result.batchId);
                                        const reward1 = graces.find(val => val.reward === 1 && val.userId === result.userId && val.batchId === result.batchId);
                                        const reward2 = graces.find(val => val.reward === 2 && val.userId === result.userId && val.batchId === result.batchId);
                                        const reward3 = graces.find(val => val.reward === 3 && val.userId === result.userId && val.batchId === result.batchId);
                                        
                                        return {
                                             ...result,
                                             performanceReward1Grace: reward1?.grace || 0,
                                             performanceReward2Grace: reward2?.grace || 0,
                                             performanceReward3Grace: reward3?.grace || 0,
                                             performanceGrace: reward?.grace || 0
                                        }
                                   })
                              )
                         })
                         .catch((err) => {
                              console.log(err);
                              setBatchUsersResults(res.data.userResultList
                                   .map(result =>({
                                        ...result,
                                        performanceReward1Grace: 0,
                                        performanceReward2Grace: 0,
                                        performanceReward3Grace: 0,
                                        performanceGrace: 0
                                   }))
                              )
                         })
                    }
               }
          }).catch((error) => {
               clearUserResults()
               handleCatchError(error)
          })
     }

     const handleFilterData = (label, value) => {
          let filterValue = {}
          if (label === 'parentBatch') {
               if (value?.value) {
                    setResultFilterValue({ [label]: value })
                    filterValue = { [label]: value }
                    getBatchsUsersResults(filterValue, 'parentBatch')
               } else {
                    setResultFilterValue({})
                    filterValue = {}
                    setBatches([])
                    setBatchSkills([])
                    setBatchUsers([])
                    setBatchUsersResults([])
               }
          } else if (label === 'batchId') {
               if (value?.length > 0) {
                    setResultFilterValue({ parentBatch: resultFilterValue?.parentBatch, [label]: value });
                    filterValue = { parentBatch: resultFilterValue?.parentBatch, [label]: value }
                    getBatchsUsersResults(filterValue, "childBatch")
               } else {
                    setResultFilterValue({ parentBatch: resultFilterValue?.parentBatch })
                    filterValue = { parentBatch: resultFilterValue?.parentBatch }
                    setBatchSkills([])
                    setBatchUsers([])
                    getBatchsUsersResults(filterValue, 'parentBatch')
               }
          } else {
               setResultFilterValue({ ...resultFilterValue, [label]: value });
               filterValue = { ...resultFilterValue, [label]: value }
               getBatchsUsersResults(filterValue, label)
          }
     }

     const handleRadioStatus = (e) => {
          setAveargeStatus(parseInt(e.target.value))
     }

     return (
          <>
               {loader &&
                    <Loader loaderStatus={loader} message="Loading ....." />
               }
               {confirmBox && <HandleMessage message={message} />}

               <div className="mt-5 mx-3 pt-5 main-dev">
                    <div className="d-flex  align-items-center justify-content-center flex-xl-nowrap flex-wrap gap-lg-0 gap-3 container input-option">
                         {resultFilterValue?.parentBatch &&
                              <div className="mx-lg-2 col-xl-2 col-sm-5 col-12 input-box mb-xl-0 mb-3" >
                                   <label>Skill Result</label>
                                   <div onChange={(e) => handleRadioStatus(e)}>
                                        <input type="radio" value={1} checked={aveargeStatus === 1} name="average" /> Average
                                        <input type="radio" value={0} checked={aveargeStatus === 0} name="average" className="ms-2" /> Last Score
                                   </div>
                              </div>
                         }
                         <div className="mx-lg-2 col-xl-2 col-sm-5 col-12 input-box mb-xl-0 mb-3" >
                              <label>Select Parent Batch</label>
                              <Select
                                   placeholder="Select batch"
                                   isClearable
                                   isSearchable
                                   value={resultFilterValue?.parentBatch || null}
                                   options={parentBatch}
                                   onChange={(e) => {
                                        handleFilterData('parentBatch', e);
                                   }}
                              />
                         </div>
                         <div className="mx-lg-2 col-xl-2 col-sm-5 col-12 input-box mb-xl-0 mb-3" >
                              <label>Select Batch</label>
                              <Select
                                   options={batches}
                                   placeholder={"Select Batches"}
                                   value={resultFilterValue?.batchId || null}
                                   isSearchable
                                   isClearable
                                   isMulti
                                   isDisabled={batches?.length > 1 ? false : true}
                                   onChange={(e) =>
                                        handleFilterData("batchId", e)
                                   }
                              />
                         </div>
                         <div className="mx-lg-2 col-xl-2 col-sm-5 col-12 input-box mb-xl-0 mb-3" >
                              <label>Select Skills</label>
                              <Select
                                   options={batchSkills}
                                   placeholder={"Select Skills"}
                                   value={resultFilterValue?.subCourceId || null}
                                   // isDisabled={batchUsersResults?.length > 0 ? false : true}
                                   isSearchable
                                   isClearable
                                   isMulti
                                   onChange={(e) =>
                                        handleFilterData("subCourceId", e)
                                   }
                              />
                              {/* {batchSkills?.length === 1 && <div>{batchSkills[0].label}<span className="h3 mx-2 text-dark" onClick={() => clearFilter()}>x</span></div>} */}
                         </div>
                         <div className="mx-lg-2 col-xl-2 col-sm-5 col-12 input-box mb-xl-0 mb-3" >
                              <label>Select Users</label>
                              <Select
                                   options={batchUsers?.filter(user => {
                                        if (userStatus.value !== null) {
                                             return user.userStatus === userStatus.value
                                        } else {
                                             return user
                                        }
                                   })}
                                   placeholder={"Select Users"}
                                   value={resultFilterValue?.userId || null}
                                   isSearchable
                                   isClearable
                                   isMulti
                                   // isDisabled={batchUsersResults?.length > 0 ? false : true}
                                   onChange={(e) =>
                                        handleFilterData("userId", e)
                                   }
                              />
                         </div>
                         <div className="mx-lg-2 col-xl-2 col-sm-5 col-12 input-box mb-xl-0 mb-3" >
                              <label>Users Status</label>
                              <Select
                                   options={userStatusOptions}
                                   placeholder={"Select Status"}
                                   isClearable
                                   isDisabled={batchUsersResults?.length > 1 ? false : true}
                                   onChange={(e) => {
                                        if (e !== null) {
                                             setUserStatus(e)
                                        } else {
                                             setUserStatus({ value: null })
                                        }
                                   }}
                              />
                         </div>
                         <div className="mx-lg-2 col-xl-2 col-sm-5 col-12 input-box mb-xl-0 mb-3" >
                              <label>Select Sorting</label>
                              <Select
                                   options={sortingOptions}
                                   placeholder={"Select Sorting"}
                                   value={selectedSorting}
                                   isSearchable
                                   isClearable
                                   isDisabled={batchUsersResults?.length > 1 ? false : true}
                                   onChange={(e) =>
                                        setSelectedSorting(e)
                                   }
                              />
                         </div>
                    </div>
               </div>
               {resultFilterValue &&
                    batchUsersResults?.length > 0 &&
                    <Table
                         batchs={batches}
                         sortData={selectedSorting}
                         aveargeStatus={aveargeStatus}
                         batchSkills={batchSkills}
                         batches={batches}
                         resultFilterValue={resultFilterValue}
                         attendance={attendance}
                         userStatus={userStatus}
                         batchUsersResults={batchUsersResults}
                         setHandleRemarks={setHandleRemarks}
                         handleRemarks={handleRemarks}
                         rewardWeightage={rewardWeightage}
                         skillWiseWeightage = {skillWiseWeightage}
                    />
               }
               {resultFilterValue?.parentBatch && !loader &&
                    batchUsersResults?.length === 0 && <div className="text-danger text-center my-5">No Records</div>}
          </>
     )
}

const Table = ({ batchs, sortData, aveargeStatus, batchSkills, batches, resultFilterValue, attendance, userStatus, batchUsersResults, setHandleRemarks,              handleRemarks, rewardWeightage, skillWiseWeightage }) => {

     const [openResultModal, setOpenResultModal] = useState(false)
     const [getExamData, setExamData] = useState({});
     const filterSkills = resultFilterValue?.subCourceId?.length > 0 ? resultFilterValue.subCourceId : batchSkills
     const filterBatchs = resultFilterValue?.batchId?.length > 0 ? resultFilterValue.batchId : batches
     const aveargeMarkStatus = aveargeStatus === 1 ? true : false

     const [isUpdateGraceId, setIsUpdateGraceId] = useState({id:"", performance: -1})
     const [performanceRewardGrace, setPerformanceRewardGrace] = useState(0)  
     const [isUpdateUserMarks, setIsUpdateUserMarks] = useState({id:"", ch: ""})
     const [userMarks, setUserMarks] = useState(0)
     const [modelReward, setModelReward] = useState(0)
     const handleClose = () => setOpenResultModal(false);

     const rewardArray = []
     Object.keys(rewardWeightage).map(
          reward =>{
               const batchwiseReward = {}
               batchwiseReward["batchName"] = batches.filter(batch => batch.value === rewardWeightage[reward].batchId)[0]?.label
               batchwiseReward["rewards"] = []
               Object.keys(rewardWeightage[reward]).slice(1)
               .map(key =>(
                    batchwiseReward["rewards"].push({
                         reward: key,
                         weightage: rewardWeightage[reward][key],
                    })
               ))
               rewardArray.push(batchwiseReward)
          } 
     );

     batchUsersResults?.sort((a, b) => {
          if (sortData) {
               let sortingby = sortData?.value?.split("-");
               let sortby = sortingby[0];
               let order = sortingby[1];
               if (sortby === "username") {
                    if (order === "asc") {
                         return a.userName.localeCompare(b.userName);
                    } else {
                         return b.userName.localeCompare(a.userName);
                    }
               } else if (sortby === "result") {
                    if (order === "asc") {
                         return a.avearge - b.avearge;
                    } else {
                         return b.avearge - a.avearge;
                    }
               } else if (sortby === "confirmation") {
                    if (order === "asc") {
                         return a.confirmGrade - b.confirmGrade;
                    } else {
                         return b.confirmGrade - a.confirmGrade;
                    }
               }
               else if (sortby === "skillAverage") {
                    if (order === "asc") {
                         return a.skillAverage - b.skillAverage;
                    } else {
                         return b.skillAverage - a.skillAverage;
                    }
               }
          } else {
               return b.avearge - a.avearge;
          }
     });

     batchUsersResults.sort(function (a, b) {
          return a.userStatus > b.userStatus ? 1 : -1;
     })

     const findBatchSkillWeighatge = (batchId, skillId) => {
          const findBatchSkills = batchs.find((batches) => batches.value === batchId)
          if (findBatchSkills?.skills?.length > 0) {
               const findSkill = findBatchSkills.skills.find((item) => item.skillId.toString() === skillId)
               return {weightage: findSkill?.weightage ? findSkill.weightage : 0, performanceReward: findSkill?.performanceReward ? findSkill.performanceReward : 0};
          } else {
               return 0;
          }
     };

     const countCofirmationAvearge = (userData, batchId, academicAverage, keyName) => {
          let commanSkillCount = 0;
          let totalWeighatge = 0;
          let totalSkillAvearge = 0;
          let countTraingProjectMark = 0;
          let countLiveCodingMark = 0;
          let countCommanSkillsMark = 0;
          let countSkillBasedAvearge = 0;
          let performanceReward1 = 0;
          let performanceReward2 = 0;
          let performanceReward3 = 0;
          const weightage = rewardWeightage?.filter(reward => reward.batchId === batchId)[0];
          const traingProjectSkillId = variable.confirmationAverageDetail.specialSkills[0].id
          const liveCodingTestId = variable.confirmationAverageDetail.specialSkills[1].id
          const traingProjectSkillGrade = variable.confirmationAverageDetail.specialSkills[0].proportion
          const liveCodingTestGrade = variable.confirmationAverageDetail.specialSkills[1].proportion
          const commanSkillGrade = variable.confirmationAverageDetail.commanSkill.proportion
          const academicGrade = variable.confirmationAverageDetail.academic
          userData?.subCourseResult?.forEach((results) => {
               if (results.subCourceId === traingProjectSkillId) {
                    countTraingProjectMark += results?.[keyName]
               }
               if (results.subCourceId === liveCodingTestId) {
                    countLiveCodingMark += results?.[keyName]
               }
               if (results.subCourceId !== traingProjectSkillId && results.subCourceId !== liveCodingTestId) {
                    commanSkillCount += 1
                    countCommanSkillsMark += results?.[keyName]
               }
               if (results) {
                    const {weightage, performanceReward} = findBatchSkillWeighatge(batchId, results.subCourceId);
                    totalWeighatge += weightage;
                    const finalAvearge = results?.[keyName]
                    totalSkillAvearge += finalAvearge
                    const Average = finalAvearge * (weightage / 100);
                    countSkillBasedAvearge += Average;
                    if(performanceReward === 1){
                         performanceReward1 += Average
                    }
                    else if(performanceReward === 2){
                         performanceReward2 += Average
                    }
                    else if(performanceReward === 3){
                         performanceReward3 += Average
                    }
               }
          })
          const countAvearge = academicAverage * academicGrade || 0
          const countConfirmationGrade = countTraingProjectMark * traingProjectSkillGrade + countLiveCodingMark * liveCodingTestGrade + countCommanSkillsMark / commanSkillCount * commanSkillGrade + countAvearge
          userData.avearge = totalSkillAvearge / userData?.subCourseResult?.length
          const divisor = (performanceReward1 && performanceReward2 && performanceReward3) ? 3 :
                    ((performanceReward1 && performanceReward2) || (performanceReward2 && performanceReward3) || (performanceReward1 && performanceReward3)) ? 2 : 
                    (performanceReward1 || performanceReward2 || performanceReward3) ? 1 : 0;

          const result = ((performanceReward1 + userData.performanceReward1Grace) + 
                         (performanceReward2 + userData.performanceReward2Grace) + 
                         (performanceReward3 + userData.performanceReward3Grace)) / divisor;

          const confirmationGradeMarks = weightage && Object.values(weightage). length > 1 ?
                         ((performanceReward1 + userData.performanceReward1Grace) * weightage?.performanceReward1/100 +
                         (performanceReward2 + userData.performanceReward2Grace) * weightage?.performanceReward2/100 +
                         (performanceReward3 + userData.performanceReward3Grace) * weightage?.performanceReward3/100 +
                         userData.tenMark * weightage?.tenthMarksWeightage/100 +
                         userData.twelveMark * weightage?.communicationWeightage/100 +
                         userData.communicationMarks * weightage?.twelfthMarksWeightage/100 + 
                         userData.mettlScore * weightage?.mettlWeightage/100 || 0) : 0
          
          userData.confirmGrade = confirmationGradeMarks || 0;
          userData.skillAverage = isNaN(result) ? countSkillBasedAvearge : result
          return {
               avearge: totalSkillAvearge / userData?.subCourseResult?.length,
               performanceReward1: performanceReward1,
               performanceReward2: performanceReward2,
               performanceReward3: performanceReward3,
               confirmationGrade: confirmationGradeMarks || 0,
               skillAvearge: isNaN(result) ? countSkillBasedAvearge : result,
               skillWeightageStatus: (performanceReward1 && performanceReward2 && performanceReward3) ? totalWeighatge === 300 ? true : false :
               ((performanceReward1 && performanceReward2) || (performanceReward2 && performanceReward3) || (performanceReward1 && performanceReward3)) ? totalWeighatge === 200 ? true : false : totalWeighatge === 100 ? true : false,
               totalWeighatge: totalWeighatge,
          }
     }

     const getGrade = (average) => {
          if (average >= 90) {
               return (
                    <td
                         className="bg-success text-white"
                         style={{ backgroundColor: "#198754", color: "#ffffff" }}
                    >
                         A+
                    </td>
               );
          } else if (average >= 80) {
               return (
                    <td
                         className="bg-primary text-white"
                         style={{ backgroundColor: "#0D6EFD", color: "#ffffff" }}
                    >
                         A
                    </td>
               );
          } else if (average >= 65) {
               return (
                    <td
                         className="bg-infos text-white"
                         style={{ backgroundColor: "#0DCAF0", color: "#ffffff" }}
                    >
                         B
                    </td>
               );
          } else if (average >= 50) {
               return (
                    <td
                         className="bg-warning text-white"
                         style={{ backgroundColor: "#FFC107", color: "#ffffff" }}
                    >
                         C
                    </td>
               );
          } else {
               return (
                    <td
                         className="bg-danger text-white"
                         style={{ backgroundColor: "#DC3545", color: "#ffffff" }}
                    >
                         D
                    </td>
               );
          }
     };

     const handleMarksAndModal = (examMarks, examDataList, hostId, skillName, batchName, userId, userName, examType, marksDifference) => {

          return <td className={marksDifference >= 50 && 'border border-4 border-danger border-bottom-4'}><Link to="#" onClick={() => {
               setOpenResultModal(true)
               setExamData({
                    data: examDataList,
                    hostId: hostId,
                    skill: skillName,
                    batch: batchName,
                    userId: userId,
                    userName: userName,
                    examType: examType
               })
          }}>{examMarks?.toFixed(2)}</Link></td>
     }

     const getUserExamMark = (examData, examType) => {
          let marks;
          if (examData?.[examType] && examData?.[examType]?.length > 0) {
               marks = aveargeStatus && examData?.[examType]?.length > 1 ? handleAvearge(examData?.[examType]) : examData[examType][examData[examType].length - 1]?.marks
          }
          return marks
     }

     const handleAvearge = (result) => {
          const totalMarks = result.reduce((acc, item) => {
               return acc += parseInt(item.marks)
          }, 0)
          if (totalMarks > 0) {
               return totalMarks / result.length
          } else {
               return 0
          }
     }

     const getTrainingProjectFieldsMarks = (examData, examType, fieldName) => {
          let marks
          if (examData?.[examType] && examData?.[examType]?.length > 0) {
               marks = examData[examType][examData[examType].length - 1]?.[fieldName]
          }
          return marks
     }

     const getTrainingProjectMarks = (skillId, userResults) => {
          const findUserSkillData = userResults.find(userSkill => userSkill.subCourceId === skillId)
          return {
               internalCommunication: getTrainingProjectFieldsMarks(findUserSkillData?.internalExam, 'practical', 'deadline'),
               internalAnalytics: getTrainingProjectFieldsMarks(findUserSkillData?.internalExam, 'practical', 'analytics'),
               internalDatabase: getTrainingProjectFieldsMarks(findUserSkillData?.internalExam, 'practical', 'database'),
               internalUI: getTrainingProjectFieldsMarks(findUserSkillData?.internalExam, 'practical', 'ui'),
               internalServerside: getTrainingProjectFieldsMarks(findUserSkillData?.internalExam, 'practical', 'serverside'),
               IP: getUserExamMark(findUserSkillData?.internalExam, 'practical'),
               externalCommunication: getTrainingProjectFieldsMarks(findUserSkillData?.externalExam, 'practical', 'deadline'),
               externalAnalytics: getTrainingProjectFieldsMarks(findUserSkillData?.externalExam, 'practical', 'analytics'),
               externalDatabase: getTrainingProjectFieldsMarks(findUserSkillData?.externalExam, 'practical', 'database'),
               externalUI: getTrainingProjectFieldsMarks(findUserSkillData?.externalExam, 'practical', 'ui'),
               externalServerside: getTrainingProjectFieldsMarks(findUserSkillData?.externalExam, 'practical', 'serverside'),
               EP: getUserExamMark(findUserSkillData?.externalExam, 'practical'),
               Average: aveargeStatus ? findUserSkillData?.totalAverage : findUserSkillData?.average,
               internalExam: findUserSkillData?.internalExam,
               externalExam: findUserSkillData?.externalExam,
               skillName: findUserSkillData?.skillName
          }
     }

     const getLiveCodingMarks = (skillId, userResults) => {
          const findUserSkillData = userResults.find(userSkill => userSkill.subCourceId === skillId)
          return {
               IP: getUserExamMark(findUserSkillData?.internalExam, 'practical'),
               EP: getUserExamMark(findUserSkillData?.externalExam, 'practical'),
               internalExam: findUserSkillData?.internalExam,
               externalExam: findUserSkillData?.externalExam,
               skillName: findUserSkillData?.skillName,
               Average: aveargeStatus ? findUserSkillData?.totalAverage : findUserSkillData?.average,
          }
     }

     const getCommanMarks = (skillId, userResults) => {
          const findUserSkillData = userResults.find(userSkill => userSkill.subCourceId === skillId)
          return {
               IM: getUserExamMark(findUserSkillData?.internalExam, 'mcq'),
               IP: getUserExamMark(findUserSkillData?.internalExam, 'practical'),
               EM: getUserExamMark(findUserSkillData?.externalExam, 'mcq'),
               EP: getUserExamMark(findUserSkillData?.externalExam, 'practical'),
               internalExam: findUserSkillData?.internalExam,
               externalExam: findUserSkillData?.externalExam,
               skillName: findUserSkillData?.skillName,
               Average: aveargeStatus ? findUserSkillData?.totalAverage : findUserSkillData?.average
          }
     }

     const handleConfirmationGradeField = () => {
          return true // temp fix
          if (batchSkills.filter((skills) => skills?.label === 'TrainingProject' || skills?.label === 'LiveCodingTest')?.length > 0) {
               if (resultFilterValue?.subCourceId?.length > 0) {
                    return false
               } else {
                    return true
               }
          } else {
               return false
          }
     }

     const getSkillsColumns = (skillName) => {
          const traingProjectSkillColumns = variable.confirmationAverageDetail.specialSkills[0].columns
          const liveCodingTestColumns = variable.confirmationAverageDetail.specialSkills[1].columns
          const commanSkillColumns = variable.confirmationAverageDetail.commanSkill.columns
          if (skillName === 'TrainingProject') {
               return traingProjectSkillColumns
          } else if (skillName === 'LiveCodingTest') {
               return liveCodingTestColumns
          } else {
               return commanSkillColumns
          }
     }

     const getAttendance = (empId) => {
          let attendanceData = {}
          if (attendance.length > 0) {
               attendanceData = attendance.find((u) => u.EmpCode === empId);
          }
          return attendanceData
     }

     const handleBackgroundColor = (index) => {
          if (index % 2 == 0) {
               return '#1241aa'
          } else {
               return '#4266BE'
          }
     }

     const handleText = (userId, status) => {
          if (status) {
               setHandleRemarks([...handleRemarks, userId])
          } else {
               setHandleRemarks(remarks => {
                    const copyRemark = [...remarks]
                    copyRemark.splice(copyRemark.findIndex((user) => user === userId), 1);
                    return copyRemark
               })
          }
     }

     const handlePerformanceRewardGraceUpdate = (reward, empId, grace, batchId) => {
          const token = localStorage.getItem("token");
          grace = { 
               performanceReward: reward, 
               marks: grace, 
               empId: empId, 
               batchId: batchId,
          }
          BatchManagementServices.updatePerformanceRewardGrace(grace, token)
          .then((res) => {
               alert(res.data.msg)
          })
          .catch((err) => {
               alert("Error occure while updating grace")
               console.log(err);
          })
     }  
     const handleUserMarksUpdate = (ch, userId, marks) => {
          const token = localStorage.getItem("token");
          BatchManagementServices.updateUserMarks(userId, {ch, marks}, token)
          .then((res) => {
               alert(res.data.msg)
          })
          .catch((err) => {
               alert("Error occur while updating communication marks")
               console.log(err);
          })
     }  
     const getSkillLength = (batch, group)=>{
          return batch.reduce((sum, batch) => sum + batch.skills.filter(skill => skill.performanceReward === group).length, 0) > 0
     }
     const getRewardLength = (batch)=>{
          return batch.reduce((sum, batch) => sum + batch.rewards.length, 0) > 0
     }

     return (
          <>
               <div className="info-section position-relative mx-3 text-end mt-5 d-flex justify-content-md-evenly justify-content-center align-items-center info flex-lg-row flex-column-reverse gap-lg-0 gap-3">
                    { 
                         rewardArray.length > 0 &&
                         <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                              <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">    
                                   <div class="modal-content">
                                        <div class="modal-header">
                                        <h1 class="modal-title fs-5" id="exampleModalLabel">{modelReward < 4 ? `Performance Reward ${modelReward}` : "Confirmation Reward"} Weightage</h1>
                                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                        </div>
                                        <div class="modal-body row d-flex justify-content-center">
                                             {    
                                                  modelReward < 4 ?
                                                  skillWiseWeightage.map(batch => (
                                                       batch.skills.filter(skill => skill.performanceReward === modelReward).length > 0 &&
                                                       (<div className="col-6">
                                                            <div className="text-start fw-semibold fs-5">
                                                                 Batch: {batch.batchName}
                                                            </div>
                                                            <table className="table">
                                                                 <thead>
                                                                      <tr className="text-center">
                                                                           <th>Sr</th>
                                                                           <th className="text-start">Skills</th>
                                                                           <th>Weightage</th>
                                                                      </tr>
                                                                 </thead>
                                                                 <tbody>
                                                                      {
                                                                           batch.skills.filter(skill => skill.performanceReward === modelReward)
                                                                           .map((skill, index) => (
                                                                                <tr className="text-start">
                                                                                     <td className="text-center">{index+1}</td>
                                                                                     <td>{skill.skillName}</td>
                                                                                     <td className="text-center">{skill.weighatge}%</td>
                                                                                </tr>
                                                                           ))
                                                                      }
                                                                 </tbody>
                                                            </table>
                                                       </div>)
                                                  ))
                                                  :
                                                  rewardArray.map(batch => (
                                                       batch.rewards.length > 0 &&
                                                       (<div className="col-6">
                                                            <div className="text-start fw-semibold fs-5">
                                                                 Batch: {batch.batchName}
                                                            </div>
                                                            <table className="table">
                                                                 <thead>
                                                                      <tr className="text-center">
                                                                           <th>Sr</th>
                                                                           <th className="text-start">Rewards</th>
                                                                           <th>Weightage</th>
                                                                      </tr>
                                                                 </thead>
                                                                 <tbody>
                                                                      {
                                                                           batch.rewards
                                                                           .map((reward, index) => (
                                                                                <tr className="text-start">
                                                                                     <td className="text-center">{index+1}</td>
                                                                                     <td>
                                                                                          {
                                                                                               reward?.reward === "performanceReward1" ? "Performance Reward 1" : 
                                                                                               reward?.reward === "performanceReward2" ? "Performance Reward 2" :
                                                                                               reward?.reward === "performanceReward3" ? "Performance Reward 3" :
                                                                                               reward?.reward === "tenthMarksWeightage" ? "10th Marks" :
                                                                                               reward?.reward === "twelfthMarksWeightage" ? "12th Marks" :
                                                                                               reward?.reward === "mettlWeightage" ? "Mettl Score" :
                                                                                               reward?.reward === "communicationWeightage" ? "Communication Marks" : "" 
                                                                                          }
                                                                                     </td>
                                                                                     <td className="text-center">{reward.weightage}%</td>
                                                                                </tr>
                                                                           ))
                                                                      }
                                                                 </tbody>
                                                            </table>
                                                       </div>)
                                                  ))
                                             }
                                        </div>
                                        <div class="modal-footer">
                                             <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                        </div>
                                   </div>
                              </div>
                         </div>
                    }
                    <div className="d-flex gap-2 flex-xl-row flex-lg-column flex-sm-row flex-column align-items-center justify-content-center">
                         <table className="info-table1">
                              <tbody>
                                   <tr>
                                        <th>IM</th>
                                        <td>Internal MCQ</td>
                                   </tr>
                                   <tr className="bg-gray-200">
                                        <th>EM</th>
                                        <td>External MCQ</td>
                                   </tr>
                              </tbody>
                         </table>
                         {/*  */}
                         <table className="info-table2">
                              <tbody>
                                   <tr>
                                        <th>
                                             IP
                                        </th>
                                        <td>Internal Pratical</td>
                                   </tr>
                                   <tr className="bg-gray-200">
                                        <th>
                                             EP
                                        </th>
                                        <td>External Pratical</td>
                                   </tr>
                              </tbody>
                         </table>
                         {/*  */}
                    </div>
                    <table className="info-table">
                         {/* <thead>
                              <tr style={{
                                   display: "flex",
                                   flexDirection: "column"
                              }}>
                                   <th>Marks Range</th>
                                   <th>Grade</th>
                              </tr>
                         </thead> */}
                         <tbody>
                              <tr>
                                   <th>Marks Range</th>
                                   <td>90 - 100</td>
                                   <td>80 - 89</td>
                                   <td>65 - 79</td>
                                   <td>50 - 64</td>
                                   <td>0 - 49</td>
                              </tr>
                              <tr>
                                   <th>Grade</th>
                                   <td className="text-white fw-semibold bg-success">A+</td>
                                   <td className="text-white fw-semibold bg-primary">A</td>
                                   <td className="text-white fw-semibold bg-infos">B</td>
                                   <td className="text-white fw-semibold bg-warning">C</td>
                                   <td className="text-white fw-semibold bg-danger">D</td>
                              </tr>
                         </tbody>
                        
                    </table>
                    <p></p>
                    <ReactHTMLTableToExcel
                         // id="test-table-xls-button"
                         className="download-table-xls-button btn btn-primary download-btn"
                         table={filterBatchs?.[0]?.label}
                         filename="test"
                         sheet="tablexls"
                         buttonText="Download as XLS"
                    />
               </div>

               <div className="overflow-scrolling mt-120 mb-5">
                    <div className="table-responsive overflow-scroll">
                         <table
                              className="tableScroll table table-bordered text-center mt-0 w-100 d-block d-md-table"
                              id={filterBatchs?.[0]?.label}
                              style={{ border: "1px solid black" }}
                         >
                              <thead
                                   style={{
                                        position: "sticky",
                                        backgroundColor: "#19456B",
                                        color: "white",
                                        top: 0,
                                        zIndex: 2,
                                   }}>
                                   <tr >
                                        <th
                                             rowSpan="2"
                                             style={{
                                                  position: "sticky",
                                                  backgroundColor: "#19456B",
                                                  color: "white",
                                                  top: 0
                                             }}>#</th>
                                        <th
                                             rowSpan="2"
                                             style={{
                                                  position: "sticky",
                                                  backgroundColor: "#19456B",
                                                  color: "white",
                                                  top: 0
                                             }}
                                        >
                                             USERS
                                        </th>
                                        <th
                                             rowSpan="2"
                                             style={{
                                                  position: "sticky",
                                                  backgroundColor: "#19456B",
                                                  color: "white",
                                                  top: 0
                                             }}
                                        >
                                             BATCH
                                        </th>
                                        <th
                                             rowSpan="2"
                                             style={{
                                                  position: "sticky",
                                                  backgroundColor: "#19456B",
                                                  color: "white",
                                                  top: 0
                                             }}
                                        >
                                             Emp_Id
                                        </th>
                                        <th
                                             rowSpan="2"
                                             style={{
                                                  position: "sticky",
                                                  backgroundColor: "#19456B",
                                                  color: "white",
                                                  top: 0
                                             }}
                                        >
                                             {/* Attendance */}
                                             ATTENDANCE

                                        </th>
                                        <th
                                             colSpan={3}
                                             style={{
                                                  position: "sticky",
                                                  backgroundColor: "#1241aa",
                                                  color: "white",
                                                  top: 0
                                             }}
                                        >
                                             Overall Average of Grades
                                        </th>
                                        <th
                                             title={getSkillLength(skillWiseWeightage, 1) ? "click here to show weightage" : "Performance Reward 1"}
                                             data-bs-toggle={getSkillLength(skillWiseWeightage, 1) && "modal"} 
                                             data-bs-target={getSkillLength(skillWiseWeightage, 1) &&"#exampleModal"}
                                             onClick={() => setModelReward(1)}
                                             colSpan={3}
                                             style={{
                                                  position: "sticky",
                                                  backgroundColor: "#4266BE",
                                                  cursor: "pointer",
                                                  color: "white",
                                                  top: 0
                                             }}
                                        >
                                             Performance Reward 1
                                        </th>
                                        <th
                                             title={getSkillLength(skillWiseWeightage, 2) ? "click here to show weightage" : "Performance Reward 2"}
                                             data-bs-toggle={getSkillLength(skillWiseWeightage, 2) && "modal"}
                                             data-bs-target={getSkillLength(skillWiseWeightage, 2) && "#exampleModal"}
                                             onClick={() => setModelReward(2)}

                                             colSpan={3}
                                             style={{
                                                  position: "sticky",
                                                  backgroundColor: "#1241aa",
                                                  cursor: "pointer",
                                                  color: "white",
                                                  top: 0
                                             }}
                                        >
                                             Performance Reward 2
                                        </th>
                                        <th
                                             title={getSkillLength(skillWiseWeightage, 3) ? "click here to show weightage" : "Performance Reward 3"}
                                             data-bs-toggle={getSkillLength(skillWiseWeightage, 3) && "modal"} 
                                             data-bs-target={getSkillLength(skillWiseWeightage, 3) && "#exampleModal"}
                                             onClick={() => setModelReward(3)}
                                             colSpan={3}
                                             style={{
                                                  position: "sticky",
                                                  backgroundColor: "#4266BE",
                                                  cursor: "pointer",
                                                  color: "white",
                                                  top: 0
                                             }}
                                        >
                                             Performance Reward 3
                                        </th>
                                        <th
                                             title= "Performance Reward Average"
                                             colSpan={2}
                                             style={{
                                                  position: "sticky",
                                                  backgroundColor: "#1241aa",
                                                  color: "white",
                                                  top: 0
                                             }}
                                        >
                                             Overall Performance
                                        </th>

                                        {handleConfirmationGradeField() &&
                                             <>
                                                  <th
                                                       title={getRewardLength(rewardArray) && "click here to show weightage"}
                                                       data-bs-toggle={getRewardLength(rewardArray) && "modal"}
                                                       data-bs-target={getRewardLength(rewardArray) && "#exampleModal"}
                                                       onClick={() => setModelReward(4)}
                                                       rowSpan="2"
                                                       style={{
                                                            position: "sticky",
                                                            backgroundColor: "#19456B",
                                                            cursor: "pointer",
                                                            color: "white",
                                                            top: 0
                                                       }}
                                                  >
                                                       Confirmation Average(%)
                                                  </th>
                                                  <th
                                                       rowSpan="2"
                                                       style={{
                                                            position: "sticky",
                                                            backgroundColor: "#19456B",
                                                            color: "white",
                                                            top: 0
                                                       }}
                                                  >
                                                       Confirmation Grade
                                                  </th>
                                                  <th
                                                       rowSpan="2"
                                                       style={{
                                                            position: "sticky",
                                                            backgroundColor: "#19456B",
                                                            color: "white",
                                                            top: 0
                                                       }}
                                                  >
                                                       Communication(%)
                                                  </th>
                                                  <th
                                                       rowSpan="2"
                                                       style={{
                                                            position: "sticky",
                                                            backgroundColor: "#19456B",
                                                            color: "white",
                                                            top: 0
                                                       }}
                                                  >
                                                       Mettl Score(%)
                                                  </th>
                                                  <th
                                                       rowSpan="2"
                                                       style={{
                                                            position: "sticky",
                                                            backgroundColor: "#19456B",
                                                            color: "white",
                                                            top: 0
                                                       }}
                                                  >
                                                       X
                                                  </th>
                                                  <th
                                                       rowSpan="2"
                                                       style={{
                                                            position: "sticky",
                                                            backgroundColor: "#19456B",
                                                            color: "white",
                                                            top: 0
                                                       }}
                                                  >
                                                       XII
                                                  </th>
                                                  <th
                                                       rowSpan="2"
                                                       style={{
                                                            position: "sticky",
                                                            backgroundColor: "#19456B",
                                                            color: "white",
                                                            top: 0
                                                       }}
                                                  >
                                                       Academic Average
                                                  </th>
                                             </>
                                        }
                                        {filterSkills.map((item, index) => {
                                             const getColumns = getSkillsColumns(item.label)
                                             return (
                                                  <React.Fragment key={index}>
                                                       <th
                                                            colSpan={`${getColumns.length}`}
                                                            className="text-capitalize text-white"
                                                            style={{
                                                                 backgroundColor: handleBackgroundColor(index),
                                                                 position: "sticky",
                                                                 color: "#ffffff",
                                                                 top: 0
                                                            }}
                                                       >
                                                            {item.label}
                                                       </th>
                                                  </React.Fragment>
                                             );
                                        })}
                                        <th
                                             rowSpan="2"
                                             style={{
                                                  backgroundColor: "#19456B",
                                                  color: "white",
                                                  position: "sticky",

                                             }}
                                             className="text-white"
                                        >
                                             {/* Attendance */}
                                             Exit Reason
                                        </th>
                                        <th
                                             rowSpan="2"
                                             style={{
                                                  backgroundColor: "#19456B",
                                                  color: "white",
                                                  position: "sticky",

                                             }}
                                             className="text-white"
                                        >
                                             Exit Remark
                                        </th>
                                   </tr>
                                   <tr>
                                   <th
                                        style={{
                                             position: "sticky",
                                             backgroundColor: "#19456B",
                                             color: "white",
                                             top: 0
                                        }}
                                   >
                                        RESULT(%)
                                   </th>
                                   <th
                                        style={{
                                             position: "sticky",
                                             backgroundColor: "#19456B",
                                             color: "white",
                                             top: 0
                                        }}
                                   >
                                        GRADE
                                   </th>
                                   <th
                                        style={{
                                             position: "sticky",
                                             backgroundColor: "#19456B",
                                             color: "white",
                                             top: 0
                                        }}
                                   >
                                        GRACE(%)
                                   </th>
                                   <th
                                        style={{
                                             position: "sticky",
                                             backgroundColor: "#19456B",
                                             color: "white",
                                             top: 0
                                        }}
                                   >
                                        RESULT(%)
                                   </th>
                                   <th
                                        style={{
                                             position: "sticky",
                                             backgroundColor: "#19456B",
                                             color: "white",
                                             top: 0
                                        }}
                                   >
                                        GRADE
                                   </th>
                                   <th
                                        style={{
                                             position: "sticky",
                                             backgroundColor: "#19456B",
                                             color: "white",
                                             top: 0
                                        }}
                                   >
                                        GRACE(%)
                                   </th>
                                   <th
                                        style={{
                                             position: "sticky",
                                             backgroundColor: "#19456B",
                                             color: "white",
                                             top: 0
                                        }}
                                   >
                                        RESULT(%)
                                   </th>
                                   <th
                                        style={{
                                             position: "sticky",
                                             backgroundColor: "#19456B",
                                             color: "white",
                                             top: 0
                                        }}
                                   >
                                        GRADE
                                   </th>
                                   <th
                                        style={{
                                             position: "sticky",
                                             backgroundColor: "#19456B",
                                             color: "white",
                                             top: 0
                                        }}
                                   >
                                        GRACE(%)
                                   </th>
                                   <th
                                        style={{
                                             position: "sticky",
                                             backgroundColor: "#19456B",
                                             color: "white",
                                             top: 0
                                        }}
                                   >
                                        RESULT(%)
                                   </th>
                                   <th
                                        style={{
                                             position: "sticky",
                                             backgroundColor: "#19456B",
                                             color: "white",
                                             top: 0
                                        }}
                                   >
                                        GRADE
                                   </th>
                                   <th
                                        style={{
                                             position: "sticky",
                                             backgroundColor: "#19456B",
                                             color: "white",
                                             top: 0
                                        }}
                                   >
                                        GRACE(%)
                                   </th>
                                   <th
                                        style={{
                                             position: "sticky",
                                             backgroundColor: "#19456B",
                                             color: "white",
                                             top: 0
                                        }}
                                   >
                                        RESULT(%)
                                   </th>
                                   <th
                                        style={{
                                             position: "sticky",
                                             backgroundColor: "#19456B",
                                             color: "white",
                                             top: 0
                                        }}
                                   >
                                        GRADE
                                   </th>
                                        {filterSkills.map((item, index) => {
                                             const getColumns = getSkillsColumns(item.label)
                                             return (
                                                  <React.Fragment key={index}>
                                                       {getColumns.map((i) => (
                                                            <th
                                                                 scope="col"
                                                                 className="text-uppercase text-white"
                                                                 style={{
                                                                      backgroundColor: "#19456B",
                                                                      position: "sticky",
                                                                      top: 131,
                                                                      color: "#ffffff",
                                                                      top: 0
                                                                 }}
                                                            >
                                                                 {i}
                                                            </th>
                                                       ))}
                                                  </React.Fragment>
                                             );
                                        })}
                                   </tr>
                              </thead>
                              <tbody
                                   style={{
                                        height: "20px !important",
                                        overflow: "scroll",
                                        zIndex: 0,
                                   }}>
                                   {
                                        batchUsersResults.filter((users) => {
                                             if (userStatus.value !== null) {
                                                  return users.userStatus === userStatus.value
                                             } else {
                                                  return users
                                             }
                                        }).map((result, index) => {
                                             const academicAverage = (result?.tenMark + result?.twelveMark) / 2
                                             const dynemicString = aveargeMarkStatus ? 'totalAverage' : 'average'
                                             const getCommanAveargeResults = countCofirmationAvearge(result, result?.batchId, academicAverage, dynemicString)
                                             const resultPercentage = isNaN(getCommanAveargeResults?.avearge?.toFixed(2)) ? 'Did Not Appear' : (getCommanAveargeResults?.avearge + result.performanceGrace)?.toFixed(2);
                                             return (
                                                  <tr key={index}
                                                  >
                                                       <td
                                                            style={{
                                                                 position: "sticky",
                                                                 left: -1,
                                                                 border: "1px solid black",
                                                                 color: "black",
                                                                 // zIndex: 1,
                                                                 // top: 0,
                                                                 backgroundColor: "white",
                                                                 padding: "15px",
                                                            }}>{index + 1}</td>
                                                       <td
                                                            style={{
                                                                 position: "sticky",
                                                                 color: "black",
                                                                 left: "50px",
                                                                 backgroundColor: "white",
                                                                 boxShadow: "2px 0px 13px 0px rgba(0,0,0,0.15)",
                                                                 color: result.userStatus && 'red',
                                                                 fontWeight: result.userStatus && "bold",
                                                                 // top: 0
                                                            }}>{result?.userName}</td>
                                                       <td>{result?.batchName}</td>
                                                       <td>{result?.EmpId}</td>
                                                       <td><Attedance attendanceData={getAttendance(result?.EmpId)} batchName={result?.batchName} /></td>
                                                       <td>{resultPercentage}</td>
                                                       <>{getCommanAveargeResults?.avearge ? getGrade(+getCommanAveargeResults?.avearge + result.performanceGrace) : <td></td>}</>
                                                       <td>
                                                            <div>
                                                                 {
                                                                      isUpdateGraceId.id === result.EmpId && isUpdateGraceId.performance === 0
                                                                      ?
                                                                      <div>
                                                                           <input type="number" autoFocus value={result.performanceGrace} style={{width:"50px"}} onChange={
                                                                                (e) => {
                                                                                     const newValue = +e.target.value;
                                                                                     if(newValue > -1 && newValue < 100){
                                                                                          result.performanceGrace = newValue;
                                                                                          setPerformanceRewardGrace(newValue)
                                                                                     }
                                                                                     else{
                                                                                          result.performanceGrace = 0
                                                                                     }
                                                                                }
                                                                           }/>
                                                                           <FaCheck className="ms-2 cursor-pointer" onClick={() => {
                                                                                setIsUpdateGraceId({id:"", performance: -1})
                                                                                if(performanceRewardGrace !== null){
                                                                                     handlePerformanceRewardGraceUpdate(0, result.userId, result.performanceGrace, result.batchId)
                                                                                     setPerformanceRewardGrace(null)
                                                                                }
                                                                           }}/>
                                                                      </div>
                                                                      :
                                                                      <div>
                                                                           {getCommanAveargeResults?.avearge > 0 &&
                                                                                <div>
                                                                                     {result.performanceGrace?.toFixed(2) || 0}
                                                                                     {
                                                                                          (isUpdateGraceId.id === "" && isUpdateGraceId.performance === -1) &&
                                                                                          ["Admin"].includes(jwt_decode(localStorage.getItem("token")).role) &&
                                                                                          <FaPen className="color-white text-black fa-sm ms-2 cursor-pointer" onClick={
                                                                                               () => {
                                                                                                    setIsUpdateGraceId({id:result.EmpId , performance: 0})
                                                                                                    setPerformanceRewardGrace(null)
                                                                                               }
                                                                                          }/>
                                                                                     }
                                                                                </div>
                                                                           }
                                                                      </div>
                                                                 }
                                                            </div>
                                                       </td>
                                                       <td>{ getCommanAveargeResults?.performanceReward1 > 0 && (getCommanAveargeResults?.performanceReward1 + result.performanceReward1Grace).toFixed(2) }</td>
                                                       <>{getCommanAveargeResults?.performanceReward1 ? getGrade(getCommanAveargeResults?.performanceReward1 + result.performanceReward1Grace) : <td></td>}</>
                                                       <td>
                                                            <div>
                                                                 {
                                                                      isUpdateGraceId.id === result.EmpId && isUpdateGraceId.performance === 1
                                                                      ?
                                                                      <div>
                                                                           <input type="number" autoFocus value={result.performanceReward1Grace} style={{width:"50px"}} onChange={
                                                                                (e) => {
                                                                                     const newValue = +e.target.value;
                                                                                     if(newValue > -1 && newValue < 100){
                                                                                          result.performanceReward1Grace = newValue;
                                                                                          setPerformanceRewardGrace(newValue)
                                                                                     }
                                                                                     else{
                                                                                          result.performanceReward1Grace = 0
                                                                                     }
                                                                                }
                                                                           }/>
                                                                           <FaCheck className="ms-2 cursor-pointer" onClick={() => {
                                                                                setIsUpdateGraceId({id:"", performance: -1})
                                                                                if(performanceRewardGrace !== null){
                                                                                     handlePerformanceRewardGraceUpdate(1, result.userId, result.performanceReward1Grace, result.batchId)
                                                                                     setPerformanceRewardGrace(null)
                                                                                }
                                                                           }}/>
                                                                      </div>
                                                                      :
                                                                      <div>
                                                                           {getCommanAveargeResults?.performanceReward1 > 0 &&
                                                                                <div>
                                                                                     {result.performanceReward1Grace?.toFixed(2) || 0}
                                                                                     {
                                                                                          (isUpdateGraceId.id === "" && isUpdateGraceId.performance === -1) &&
                                                                                          ["Admin"].includes(jwt_decode(localStorage.getItem("token")).role) &&
                                                                                          <FaPen className="color-white text-black fa-sm ms-2 cursor-pointer" onClick={
                                                                                               () => {
                                                                                                    setIsUpdateGraceId({id:result.EmpId , performance: 1})
                                                                                                    setPerformanceRewardGrace(null)
                                                                                               }
                                                                                          }/>
                                                                                     }
                                                                                </div>
                                                                           }
                                                                      </div>
                                                                 }
                                                            </div>
                                                       </td>
                                                       <td>{getCommanAveargeResults?.performanceReward2 > 0 && (getCommanAveargeResults?.performanceReward2 + result.performanceReward2Grace).toFixed(2) }</td>
                                                       <>{getCommanAveargeResults?.performanceReward2 ? getGrade(getCommanAveargeResults?.performanceReward2 + result.performanceReward2Grace) : <td></td>}</>
                                                       <td>
                                                            <div>
                                                                 {
                                                                      isUpdateGraceId.id === result.EmpId && isUpdateGraceId.performance === 2
                                                                      ?
                                                                      <div>
                                                                           <input type="number" autoFocus value={result.performanceReward2Grace} style={{width:"50px"}} onChange={
                                                                                (e,index) => { 
                                                                                     const newValue = +e.target.value;
                                                                                     if(newValue > -1 && newValue < 100){
                                                                                          result.performanceReward2Grace = newValue
                                                                                          setPerformanceRewardGrace(newValue)
                                                                                     }
                                                                                     else{
                                                                                          result.performanceReward2Grace = 0
                                                                                     }
                                                                                }} />
                                                                           <FaCheck className="ms-2 cursor-pointer" onClick={() => {
                                                                                setIsUpdateGraceId({id:"", performance: -1})
                                                                                if(performanceRewardGrace !== null){
                                                                                     handlePerformanceRewardGraceUpdate(2, result.userId, result.performanceReward2Grace, result.batchId)
                                                                                     setPerformanceRewardGrace(null)
                                                                                }

                                                                           }}/>
                                                                      </div>
                                                                      :
                                                                      <div>
                                                                           { getCommanAveargeResults?.performanceReward2 > 0 &&
                                                                                <div>
                                                                                     {result.performanceReward2Grace?.toFixed(2) || 0.00}
                                                                                     {
                                                                                          (isUpdateGraceId.id === "" && isUpdateGraceId.performance === -1) && 
                                                                                          ["Admin"].includes(jwt_decode(localStorage.getItem("token")).role) &&
                                                                                          <FaPen className="color-white text-black fa-sm ms-2 cursor-pointer" onClick={
                                                                                               () => {
                                                                                                    setIsUpdateGraceId({id:result.EmpId , performance: 2})
                                                                                                    setPerformanceRewardGrace(null)
                                                                                               }
                                                                                          }/>
                                                                                     }
                                                                                </div>
                                                                           }
                                                                      </div>
                                                                 }
                                                            </div>
                                                       </td>
                                                       <td>{ getCommanAveargeResults?.performanceReward3 > 0 && (getCommanAveargeResults?.performanceReward3 + result.performanceReward3Grace).toFixed(2) }</td>
                                                       <>{getCommanAveargeResults?.performanceReward3 ? getGrade(getCommanAveargeResults?.performanceReward3 + result.performanceReward3Grace) : <td></td>}</>
                                                       <td>
                                                            <div>
                                                                 {
                                                                      isUpdateGraceId.id === result.EmpId && isUpdateGraceId.performance === 3
                                                                      ?
                                                                      <div>
                                                                           <input type="number" autoFocus value={result.performanceReward3Grace || 0} style={{width:"50px"}} onChange={
                                                                                e => { 
                                                                                     const newValue = +e.target.value;
                                                                                     if(newValue > -1 && newValue < 100){
                                                                                          result.performanceReward3Grace = newValue
                                                                                          setPerformanceRewardGrace(newValue)
                                                                                     }
                                                                                     else{
                                                                                          result.performanceReward3Grace = 0
                                                                                     }
                                                                                }}/>
                                                                           <FaCheck className="ms-2 cursor-pointer" onClick={() => {
                                                                                     setIsUpdateGraceId({id:"", performance: -1})
                                                                                     if(performanceRewardGrace !== null){
                                                                                          handlePerformanceRewardGraceUpdate(3, result.userId, result.performanceReward3Grace, result.batchId)
                                                                                          setPerformanceRewardGrace(null)
                                                                                     }
                                                                                }
                                                                           }/>
                                                                      </div>
                                                                      :
                                                                      <div>
                                                                           {getCommanAveargeResults?.performanceReward3 > 0 && 
                                                                                <div>
                                                                                     {(result.performanceReward3Grace?.toFixed(2) || 0.00)}
                                                                                     {
                                                                                          (isUpdateGraceId.id === "" && isUpdateGraceId.performance === -1) &&
                                                                                          ["Admin"].includes(jwt_decode(localStorage.getItem("token")).role) &&
                                                                                          <FaPen className="color-white text-black fa-sm ms-2 cursor-pointer" onClick={
                                                                                               () => {
                                                                                                    setIsUpdateGraceId({id:result.EmpId , performance: 3})
                                                                                                    setPerformanceRewardGrace(null)
                                                                                               }
                                                                                          }/>
                                                                                     }
                                                                                </div>
                                                                           }
                                                                      </div>
                                                                 }
                                                            </div>
                                                       </td>
                                                       {handleConfirmationGradeField() &&
                                                            <>
                                                                 {getCommanAveargeResults?.totalWeighatge > 0 ? <td className={getCommanAveargeResults?.skillWeightageStatus ? 'text-success' : 'text-danger'}>{getCommanAveargeResults?.skillAvearge?.toFixed(2)}</td> : <td></td>}
                                                                 {getCommanAveargeResults?.totalWeighatge > 0 ? getGrade(getCommanAveargeResults?.skillAvearge) : <td></td>}
                                                                 <td>{getCommanAveargeResults?.confirmationGrade?.toFixed(2)}</td>
                                                                 <>{getCommanAveargeResults?.confirmationGrade ? getGrade(getCommanAveargeResults?.confirmationGrade) : <td></td>}</>
                                                                 <td>
                                                                      <div>
                                                                           {
                                                                                isUpdateUserMarks.id === result.EmpId && isUpdateUserMarks.ch === "communicationMarks"
                                                                                ?
                                                                                <div>
                                                                                     <input type="number" autoFocus value={result.communicationMarks} style={{width:"50px"}} onChange={
                                                                                          (e) => {
                                                                                               const newValue = +e.target.value;
                                                                                               if(newValue > -1 && newValue < 100){
                                                                                                    result.communicationMarks = newValue;
                                                                                                    setUserMarks(newValue)
                                                                                               }
                                                                                               else{
                                                                                                    result.communicationMarks = 0
                                                                                               }
                                                                                          }
                                                                                     }/>
                                                                                     <FaCheck className="ms-2 cursor-pointer" onClick={() => {
                                                                                          setIsUpdateUserMarks({id:"", ch: ""})
                                                                                          if(userMarks !== null){
                                                                                               handleUserMarksUpdate("communicationMarks", result.userId, result.communicationMarks)
                                                                                               setUserMarks(null)
                                                                                          }
                                                                                     }}/>
                                                                                </div>
                                                                                :
                                                                                <div>
                                                                                     <div>
                                                                                          {result.communicationMarks?.toFixed(2) || 0}
                                                                                          {
                                                                                               isUpdateUserMarks.id === "" && isUpdateUserMarks.ch === "" && 
                                                                                               !["HR"].includes(jwt_decode(localStorage.getItem("token")).role) &&
                                                                                               <FaPen className="color-white text-black fa-sm ms-2 cursor-pointer" onClick={
                                                                                                    () => {
                                                                                                         setIsUpdateUserMarks({id:result.EmpId, ch:"communicationMarks"})
                                                                                                         setUserMarks(null)
                                                                                                    }
                                                                                               }/>
                                                                                          }
                                                                                     </div>
                                                                                </div>
                                                                           }
                                                                      </div>

                                                                 </td>
                                                                 <td>
                                                                      <div>
                                                                           {
                                                                                isUpdateUserMarks.id === result.EmpId && isUpdateUserMarks.ch === "mettlScore"
                                                                                ?
                                                                                <div>
                                                                                     <input type="number" autoFocus value={result.mettlScore} style={{width:"50px"}} onChange={
                                                                                          (e) => {
                                                                                               const newValue = +e.target.value;
                                                                                               if(newValue > -1 && newValue < 100){
                                                                                                    result.mettlScore = newValue;
                                                                                                    setUserMarks(newValue)
                                                                                               }
                                                                                               else{
                                                                                                    result.mettlScore = 0
                                                                                               }
                                                                                          }
                                                                                     }/>
                                                                                     <FaCheck className="ms-2 cursor-pointer" onClick={() => {
                                                                                          setIsUpdateUserMarks({id:"", ch:""})
                                                                                          if(userMarks !== null){
                                                                                               handleUserMarksUpdate("mettlScore",result.userId, result.mettlScore)
                                                                                               setUserMarks(null)
                                                                                          }
                                                                                     }}/>
                                                                                </div>
                                                                                :
                                                                                <div>
                                                                                     <div>
                                                                                          {result.mettlScore?.toFixed(2) || 0}
                                                                                          {    
                                                                                               isUpdateUserMarks.id === "" && isUpdateUserMarks.ch === "" && 
                                                                                               !["Trainer"].includes(jwt_decode(localStorage.getItem("token")).role) &&
                                                                                               <FaPen className="color-white text-black fa-sm ms-2 cursor-pointer" onClick={
                                                                                                    () => {
                                                                                                         setIsUpdateUserMarks({ch:"mettlScore", id:result.EmpId})
                                                                                                         setUserMarks(null)
                                                                                                    }
                                                                                               }/>
                                                                                          }
                                                                                     </div>
                                                                                </div>
                                                                           }
                                                                      </div>
                                                                 </td>
                                                                 <td>
                                                                 <div>
                                                                           {
                                                                                isUpdateUserMarks.id === result.EmpId && isUpdateUserMarks.ch === "tenthMarks"
                                                                                ?
                                                                                <div>
                                                                                     <input type="number" autoFocus value={result.tenMark} style={{width:"50px"}} onChange={
                                                                                          (e) => {
                                                                                               const newValue = +e.target.value;
                                                                                               if(newValue > -1 && newValue < 100){
                                                                                                    result.tenMark = newValue;
                                                                                                    setUserMarks(newValue)
                                                                                               }
                                                                                               else{
                                                                                                    result.tenMark = 0
                                                                                               }
                                                                                          }
                                                                                     }/>
                                                                                     <FaCheck className="ms-2 cursor-pointer" onClick={() => {
                                                                                          setIsUpdateUserMarks({id:"", ch:""})
                                                                                          if(userMarks !== null){
                                                                                               handleUserMarksUpdate("tenthMarks",result.userId, result.tenMark)
                                                                                               setUserMarks(null)
                                                                                          }
                                                                                     }}/>
                                                                                </div>
                                                                                :
                                                                                <div>
                                                                                     <div>
                                                                                          {result.tenMark?.toFixed(2) || 0}
                                                                                          {    
                                                                                               isUpdateUserMarks.id === "" && isUpdateUserMarks.ch === "" && 
                                                                                               !["Trainer"].includes(jwt_decode(localStorage.getItem("token")).role) &&
                                                                                               <FaPen className="color-white text-black fa-sm ms-2 cursor-pointer" onClick={
                                                                                                    () => {
                                                                                                         setIsUpdateUserMarks({ch:"tenthMarks", id:result.EmpId})
                                                                                                         setUserMarks(null)
                                                                                                    }
                                                                                               }/>
                                                                                          }
                                                                                     </div>
                                                                                </div>
                                                                           }
                                                                      </div>
                                                                 </td>
                                                                 <td>
                                                                 <div>
                                                                           {
                                                                                isUpdateUserMarks.id === result.EmpId && isUpdateUserMarks.ch === "twelfthMarks"
                                                                                ?
                                                                                <div>
                                                                                     <input type="number" autoFocus value={result.twelveMark} style={{width:"50px"}} onChange={
                                                                                          (e) => {
                                                                                               const newValue = +e.target.value;
                                                                                               if(newValue > -1 && newValue < 100){
                                                                                                    result.twelveMark = newValue;
                                                                                                    setUserMarks(newValue)
                                                                                               }
                                                                                               else{
                                                                                                    result.twelveMark = 0
                                                                                               }
                                                                                          }
                                                                                     }/>
                                                                                     <FaCheck className="ms-2 cursor-pointer" onClick={() => {
                                                                                          setIsUpdateUserMarks({id:"", ch:""})
                                                                                          if(userMarks !== null){
                                                                                               handleUserMarksUpdate("twelfthMarks",result.userId, result.twelveMark)
                                                                                               setUserMarks(null)
                                                                                          }
                                                                                     }}/>
                                                                                </div>
                                                                                :
                                                                                <div>
                                                                                     <div>
                                                                                          {result.twelveMark?.toFixed(2) || 0}
                                                                                          {    
                                                                                               isUpdateUserMarks.id === "" && isUpdateUserMarks.ch === "" && 
                                                                                               !["Trainer"].includes(jwt_decode(localStorage.getItem("token")).role) &&
                                                                                               <FaPen className="color-white text-black fa-sm ms-2 cursor-pointer" onClick={
                                                                                                    () => {
                                                                                                         setIsUpdateUserMarks({ch:"twelfthMarks", id:result.EmpId})
                                                                                                         setUserMarks(null)
                                                                                                    }
                                                                                               }/>
                                                                                          }
                                                                                     </div>
                                                                                </div>
                                                                           }
                                                                      </div>
                                                                 </td>
                                                                 <td>{academicAverage?.toFixed(2)}</td>
                                                            </>
                                                       }
                                                       {filterSkills.map((skills) => {
                                                            if (skills.label === 'TrainingProject') {
                                                                 var getTrainingProjectResults = getTrainingProjectMarks(skills.value, result.subCourseResult)
                                                            } else if (skills.label === 'LiveCodingTest') {
                                                                 var getLiveCodingResults = getLiveCodingMarks(skills.value, result.subCourseResult)
                                                            } else {
                                                                 var getCommanResults = getCommanMarks(skills.value, result.subCourseResult)
                                                            }
                                                            const trainingProjectPracticalMarkDiffernce = getTrainingProjectResults?.IP - getTrainingProjectResults?.EP
                                                            const LiveCodingTestPracticalMarkDiffernce = getLiveCodingResults?.IP - getLiveCodingResults?.EP
                                                            const commanSkillMcqsMarkDiffernce = getCommanResults?.IM - getCommanResults?.EM
                                                            const commanSkillPracticalMarkDiffernce = getCommanResults?.IP - getCommanResults?.EP
                                                            return (
                                                                 <>
                                                                      {skills.label === 'TrainingProject' &&
                                                                           <>
                                                                                <td>{getTrainingProjectResults?.internalCommunication?.toFixed(2)}</td>
                                                                                <td>{getTrainingProjectResults?.internalAnalytics?.toFixed(2)}</td>
                                                                                <td>{getTrainingProjectResults?.internalDatabase?.toFixed(2)}</td>
                                                                                <td>{getTrainingProjectResults?.internalUI?.toFixed(2)}</td>
                                                                                <td>{getTrainingProjectResults?.internalServerside?.toFixed(2)}</td>
                                                                                <>{handleMarksAndModal(getTrainingProjectResults?.IP, getTrainingProjectResults?.internalExam?.practical, result?.hostId, getTrainingProjectResults?.skillName, result?.batchName, result?.userId, result?.userName, 'internalExam', trainingProjectPracticalMarkDiffernce)}</>
                                                                                <td>{getTrainingProjectResults?.externalCommunication?.toFixed(2)}</td>
                                                                                <td>{getTrainingProjectResults?.externalAnalytics?.toFixed(2)}</td>
                                                                                <td>{getTrainingProjectResults?.externalDatabase?.toFixed(2)}</td>
                                                                                <td>{getTrainingProjectResults?.externalUI?.toFixed(2)}</td>
                                                                                <td>{getTrainingProjectResults?.externalServerside?.toFixed(2)}</td>
                                                                                <>{handleMarksAndModal(getTrainingProjectResults?.EP, getTrainingProjectResults?.externalExam?.practical, result?.hostId, getTrainingProjectResults?.skillName, result?.batchName, result?.userId, result?.userName, 'externalExam', trainingProjectPracticalMarkDiffernce)}</>
                                                                                <td>{getTrainingProjectResults?.Average?.toFixed(2)}</td>
                                                                                <>{getTrainingProjectResults?.Average ? getGrade(getTrainingProjectResults?.Average) : <td></td>}</>
                                                                           </>
                                                                      }
                                                                      {skills.label === 'LiveCodingTest' &&
                                                                           <>
                                                                                <>{handleMarksAndModal(getLiveCodingResults?.IP, getLiveCodingResults?.internalExam?.practical, result?.hostId, getLiveCodingResults?.skillName, result?.batchName, result?.userId, result?.userName, 'internalExam', LiveCodingTestPracticalMarkDiffernce)}</>
                                                                                <>{handleMarksAndModal(getLiveCodingResults?.EP, getLiveCodingResults?.externalExam?.practical, result?.hostId, getLiveCodingResults?.skillName, result?.batchName, result?.userId, result?.userName, 'externalExam', LiveCodingTestPracticalMarkDiffernce)}</>
                                                                                <td>{getLiveCodingResults?.Average?.toFixed(2)}</td>
                                                                                <>{getLiveCodingResults?.Average ? getGrade(getLiveCodingResults?.Average) : <td></td>}</>
                                                                           </>
                                                                      }
                                                                      {skills.label !== 'TrainingProject' && skills.label !== 'LiveCodingTest' &&
                                                                           <>
                                                                                <>{handleMarksAndModal(getCommanResults?.IM, getCommanResults?.internalExam?.mcq, result?.hostId, getCommanResults?.skillName, result?.batchName, result?.userId, result?.userName, 'internalExam', commanSkillMcqsMarkDiffernce)}</>
                                                                                <>{handleMarksAndModal(getCommanResults?.EM, getCommanResults?.externalExam?.mcq, result?.hostId, getCommanResults?.skillName, result?.batchName, result?.userId, result?.userName, 'externalExam', commanSkillMcqsMarkDiffernce)}</>
                                                                                <>{handleMarksAndModal(getCommanResults?.IP, getCommanResults?.internalExam?.practical, result?.hostId, getCommanResults?.skillName, result?.batchName, result?.userId, result?.userName, 'internalExam', commanSkillPracticalMarkDiffernce)}</>
                                                                                <>{handleMarksAndModal(getCommanResults?.EP, getCommanResults?.externalExam?.practical, result?.hostId, getCommanResults?.skillName, result?.batchName, result?.userId, result?.userName, 'externalExam', commanSkillPracticalMarkDiffernce)}</>
                                                                                <td>{getCommanResults?.Average?.toFixed(2)}</td>
                                                                                <>{getCommanResults?.Average ? getGrade(getCommanResults?.Average) : <td></td>}</>
                                                                           </>
                                                                      }

                                                                 </>
                                                            )
                                                       })}
                                                       <td>{result?.leaveReason}</td>
                                                       <td>{result?.Remark?.length > 50 ? handleRemarks.includes(result.userId) ? <>{result?.Remark}<span className="text-danger mx-2 text-decoration-underline" onClick={() => handleText(result.userId, false)}>Show less</span></> : <>{result?.Remark?.substring(0, 10)}<span className="text-success mx-2 text-decoration-underline" onClick={() => handleText(result.userId, true)}>Show more</span></> : result.Remark}</td>
                                                  </tr>
                                             )
                                        })
                                   }
                              </tbody>
                              <Modal size="xl" show={openResultModal} onHide={handleClose}>
                                   <Modal.Header closeButton>
                                        <Modal.Title>
                                             <div>Exam Data</div>
                                             <div className="d-flex">
                                                  <div><span className="text-primary">BatchName</span> : {getExamData?.batch}</div>
                                                  <div className="mx-2"><span className="text-primary">SkillName</span> : {getExamData?.skill}</div>
                                                  <div><span className="text-primary">UserName</span> : {getExamData?.userName}</div>
                                                  <div className="mx-2"><span className="text-primary">ExamType</span> : {getExamData?.examType}</div>
                                             </div>
                                        </Modal.Title>
                                   </Modal.Header>
                                   <Modal.Body>
                                        {getExamData?.data?.length > 0 ? <table class="table">
                                             <thead>
                                                  <tr>
                                                       <th scope="col">
                                                            #
                                                       </th>
                                                       <th scope="col">
                                                            Exam Code
                                                       </th>
                                                       <th scope="col">
                                                            Mark(%)
                                                       </th>
                                                       <th scope="col">
                                                            Grade
                                                       </th>
                                                       {getExamData?.data?.find((item) => (item.remark ? item.remark : false)) ? <><th scope="col">
                                                            Remarks
                                                       </th><th scope="col">
                                                                 Video Link
                                                            </th><th scope="col">
                                                                 Review By
                                                            </th></> : <th scope="col">
                                                            Answer
                                                       </th>}
                                                       <th scope="col">
                                                            Date
                                                       </th>
                                                  </tr>
                                             </thead>
                                             <tbody id="tableBody">
                                                  {
                                                       getExamData?.data?.map((marks, index) => {
                                                            const date = new Date(marks.reviewDate);
                                                            let temp = `${date.getDate()}/${date.getMonth() + 1
                                                                 }/${date.getFullYear()}`;
                                                            return (
                                                                 <tr>
                                                                      <td>{index + 1}</td>
                                                                      {marks?.examId?.examId ? <td>{marks.examId.examId}</td> : <td>{marks.examId}</td>}
                                                                      <td>{marks && marks.marks.toFixed(2)}</td>
                                                                      <td>{getGrade(marks.marks)}</td>
                                                                      {marks?.remark ? <>
                                                                           <td>{marks.remark}</td>
                                                                           <td><a
                                                                                target="_blank"
                                                                                href={`/ExamVideo/${getExamData?.batch}/${getExamData?.skill}/${marks.examId
                                                                                     }/${getExamData?.hostId}/${getExamData?.userId
                                                                                     }`}
                                                                           >
                                                                                <i class="fa-solid fa-play-circle fs-5"></i>
                                                                           </a></td>
                                                                           <td>
                                                                                {marks?.reviewBy}</td></> : <td><Link to={`/correctanswer/${marks.examId._id}/${getExamData?.userId}/${getExamData?.examType}`} target="_blank"><BiCommentDetail
                                                                                ></BiCommentDetail></Link></td>}
                                                                      <td>{temp}</td>
                                                                 </tr>
                                                            )
                                                       })
                                                  }
                                             </tbody>
                                        </table> : <span className="text-danger text-center">No data founded</span>}
                                   </Modal.Body>
                                   <Modal.Footer></Modal.Footer>
                              </Modal>
                         </table>
                    </div>
               </div>
          </>

     )
}

function Attedance({ attendanceData, batchName }) {
     const [show, setShow] = useState(false);

     const handleClose = () => setShow(false);
     const handleShow = () => setShow(true);

     const formatDate = (date) => {
          if (date) {
               let today = new Date(date);
               const yyyy = today.getFullYear();
               let mm = today.getMonth() + 1; // Months start at 0!
               let dd = today.getDate();

               if (dd < 10) dd = "0" + dd;
               if (mm < 10) mm = "0" + mm;

               today = dd + "/" + mm + "/" + yyyy;
               return today;
          } else {
               return;
          }
     };
     var leavedetails = [];
     if (attendanceData && attendanceData.LeaveDetails) {
          leavedetails = JSON.parse(attendanceData.LeaveDetails);
     }

     return (
          <>
               {attendanceData ? (
                    <>
                         {attendanceData?.PresentDays && attendanceData?.TotalWorkingDays && <p className="text-primary btn border-bottom" onClick={handleShow}>
                              {attendanceData.PresentDays} / {attendanceData.TotalWorkingDays}
                         </p>}
                         <Modal size="lg" show={show} onHide={handleClose}>
                              <Modal.Header closeButton>
                                   <Modal.Title>
                                        <div className="d-flex">
                                             <div>Attendance</div>
                                             <div className="ms-5"><span className="text-primary">BatchName</span> : {batchName}</div>
                                        </div>  </Modal.Title>
                              </Modal.Header>
                              <Modal.Body>
                                   <div className="row">
                                        <div className="col col-6">
                                             <p>User: {attendanceData.EmployeeName}</p>
                                        </div>
                                        <div className="col col-6">
                                             <p>Joining Date: {formatDate(attendanceData.JoinDate)}</p>
                                        </div>
                                        <div className="col col-6">
                                             <p>
                                                  Present Days: {attendanceData.PresentDays} /{" "}
                                                  {attendanceData.TotalWorkingDays}{" "}
                                             </p>
                                        </div>
                                        <div className="col col-6">
                                             <p>Leave Days: {attendanceData.LeaveDays || 0}</p>
                                        </div>
                                        <div className="col col-4">
                                             <p>Productive Time: {attendanceData.TotalProductiveTime}</p>
                                        </div>
                                        <div className="col col-4">
                                             <p>Desktime: {attendanceData.TotalDeskTime}</p>
                                        </div>
                                        <div className="col col-4">
                                             <p>Required Desktime: {attendanceData.RequiredDeskTime}</p>
                                        </div>
                                   </div>

                                   {leavedetails.length > 0 && (
                                        <>
                                             <p>Leave Details:</p>
                                             <table id="table" class="table">
                                                  <thead>
                                                       <tr>
                                                            <th scope="col">#</th>
                                                            <th scope="col">Status</th>
                                                            <th scope="col">Type</th>
                                                            <th scope="col">Days</th>
                                                            <th scope="col">From Date</th>
                                                            <th scope="col">To Date</th>
                                                            <th scope="col">Reason</th>
                                                       </tr>
                                                  </thead>
                                                  <tbody id="tableBody">
                                                       {leavedetails.map((details, index) => {
                                                            return (
                                                                 <tr>
                                                                      <td>{index + 1}</td>
                                                                      <td>{details.LeaveStatus}</td>
                                                                      <td>{details.LeaveType}</td>
                                                                      <td>{details.Days}</td>
                                                                      <td>{details.FromDate}</td>
                                                                      <td>{details.ToDate}</td>
                                                                      <td>{details.Reason}</td>
                                                                 </tr>
                                                            );
                                                       })}
                                                  </tbody>
                                             </table>
                                        </>
                                   )}
                              </Modal.Body>
                              <Modal.Footer>
                                   <Button variant="secondary" onClick={handleClose}>
                                        Close
                                   </Button>
                              </Modal.Footer>
                         </Modal>
                    </>
               ) : (
                    <></>
               )}
          </>
     );
}
