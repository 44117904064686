import React, { useEffect, useState } from "react";
import { VscCheck } from "react-icons/vsc";
import { useLocation, useNavigate } from "react-router-dom";
import BatchManagementServices from "../../services/BatchManagementServices";
import formloader from "../../images/formloading.svg";

export default function UpdateSkillDaysAndWeightage() {
  const [skills, setSkills] = useState([]);
  const [confirmbox, setConfirmbox] = useState(false);
  const [Msg, setMsg] = useState({ status: true, message: "" });
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const { state } = useLocation();
  const [loading, setLoading] = useState(false);
  const [performanceReward, setPerformanceReward] = useState('')
  const [updatedSkills, setUpdatedSkills] = useState([])
  const [replica, setReplica] = useState([]);
  const [saveWeightageBtn, setSaveWeightageBtn] = useState(false)
  const [confirmationGradeWeightage, setConfirmationGradeWeightage] = useState({
    performanceReward1Weightage: state?.performanceReward1Weightage || 20,    
    performanceReward2Weightage: state?.performanceReward2Weightage || 30,   
    performanceReward3Weightage: state?.performanceReward3Weightage || 50,
    tenthMarksWeightage: state?.tenthMarksWeightage || 0,
    twelfthMarksWeightage: state?.twelfthMarksWeightage || 0,
    communicationWeightage: state?.communicationWeightage || 0,
    mettlWeightage: state?.mettlWeightage || 0
  })

  useEffect(()=>{
    const prevState =[]
    updatedSkills.length && updatedSkills.map(updatedSkill => {
      prevState.push(...replica.filter(skill => updatedSkill.id === skill.id).map(skill => skill.id))
    })
    setSkills(skills => skills.map(
      skill => prevState.includes(skill.id) ? JSON.parse(JSON.stringify(replica.find(replicaSkill => replicaSkill.id === skill.id))) : skill
    ))
    setUpdatedSkills([])
  },[performanceReward])

  const handleUpdatePerformance = () => {
    const filteredSkills = updatedSkills.filter(skill => skill.performanceReward === performanceReward)
    if (
      performanceReward !== "" && 
      filteredSkills.length &&
      skills.map(skill => skill.performanceReward === performanceReward && skill.weightage).reduce((a,b) => a+=b) === 100
    ) {
      const skills = filteredSkills.map(
        skill => ({
          weightage:skill.weightage, 
          id:skill.id, 
          skillName:skill.label, 
          trainingPlansId:skill.trainingPlansId, 
          performanceReward:skill.performanceReward
        }))
      BatchManagementServices.updateSkillWeightage(state._id, {skills: skills}, token)
        .then((res) => {
          if (res.data) {
            setLoading(false)
            setConfirmbox(true);
            setMsg({ status: res.data.status, message: res.data.msg });
            setTimeout(() => {
              setConfirmbox(false);
            }, 4000);
          }
          setUpdatedSkills(skills => skills.filter(skill => skill.performanceReward !== performanceReward))
        })
        .catch((err) => {
          setLoading(false);
          setConfirmbox(true);
          setMsg({ status: false, message: err.msg || "Network Error" });
          setTimeout(() => {
            setConfirmbox(false);
          }, 4000);
        });
    }
    else{
      const msg = performanceReward === "" ? 
      "Please select a performance reward." 
      :
      !filteredSkills.length ? "No changes were found to update." 
      :
      skills.map(skill => skill.performanceReward === performanceReward && skill.weightage).reduce((a,b) => a+=b) !== 100 
      ?
      `The weightage is updated when the total performance reward reaches 100 (performance reward ${performanceReward}).`
      :
      "Somthing went wrong"
      setConfirmbox(true);
      setMsg({ 
        status: false, 
        message: msg
      });
      setTimeout(() => {
        setConfirmbox(false);
      }, 4000);    
    }
  };

  const handleUpdateSkill = (skill) => {
    if (skill.days !== "") {
      BatchManagementServices.updateSkillDays(state._id, skill, token)
        .then((res) => {
          if (res.data) {
            setLoading(false)
            setConfirmbox(true);
            setMsg({ status: res.data.status, message: res.data.msg });
            setTimeout(() => {
              setConfirmbox(false);
            }, 4000);
          }
        })
        .catch((err) => {
          console.log(err)
          setLoading(false);
          setConfirmbox(true);
          setMsg({ status: false, message: "Network Error" });
          setTimeout(() => {
            setConfirmbox(false);
          }, 4000);
        });
    }
  };

  useEffect(() => {
    if (!state) {
      return;
    }

    if (state?.skills.length > 0) {
      const temp1 = [];
      state.skills.forEach((element) => {
        temp1.push({
          label: element.skillId.name,
          value: element.skillId.name,
          id: element.skillId._id,
          days:
            element?.days > 0
              ? element.days
              : element.trainingPlansId?.length > 0
              ? element.trainingPlansId.length
              : "",
          weightage: element.weightage ? element.weightage : "",
          performanceReward: element?.performanceReward || "",
          daysLength:
            element.trainingPlansId?.length > 0
              ? element.trainingPlansId.length
              : 0,
          daysError: "",
          trainingPlansId: element.trainingPlansId,
        });
      });

      setSkills([...temp1]);
      setReplica(JSON.parse(JSON.stringify(temp1)));
    }
  }, []);

  const handleWeightage = (e, id) => {
    const list = [...skills];
    const findSkill = skills.find((item) => item.id === id);
    if (
      !e.target.value ||
      (parseFloat(e.target.value) <= 100 && parseFloat(e.target.value) >= 0) &&
      performanceReward !== ''
    ) {
      findSkill.weightage = parseFloat(e.target.value);
      findSkill.performanceReward = performanceReward;
      setSkills(list);
      setUpdatedSkills(skills => {
        const existingSkillIndex = skills.findIndex(skill => skill.id === findSkill.id);
        if (existingSkillIndex !== -1) {
          const updatedSkills = [...skills];
          updatedSkills[existingSkillIndex] = { ...updatedSkills[existingSkillIndex], weightage: findSkill.weightage };
          return updatedSkills;
        } else {
          return [...skills, findSkill];
        }
      });
    }
  };

  const handleDays = (e, id) => {
    const list = [...skills];
    const findSkill = skills.find((item) => item.id === id);
    if (!e.target.value || parseInt(e.target.value) <= 0) {
      findSkill.days = "";
      findSkill.daysError = `Required day filed`;
      setSkills(list);
    } else {
      if (parseInt(e.target.value) > 99) {
        findSkill.days = "";
        findSkill.daysError = `Number of Days should be less than 99`;
        setSkills(list);
      } else {
        findSkill.days = parseInt(e.target.value);
        findSkill.daysError = "";
        setSkills(list);
      }
    }
  };

  const countPerformanceWeightage = (group) => {
    if (skills?.length > 0) {
      return skills.filter(skill=> skill?.performanceReward === group).reduce((acc, skill) => {
        return (acc += skill?.weightage ? skill.weightage : 0);
      }, 0);
    } else {
      return 0;
    }
  };

  const addRewardWeightage = () =>{
    if(countTotalOfGradeWeightage() === 100){
      BatchManagementServices.alterRewardWeightage(state._id, confirmationGradeWeightage, token)
      .then(resp => {
        setLoading(false)
        setConfirmbox(true);
        setMsg({ status: resp.data.status, message: resp.data.msg });
        setTimeout(() => {
          setConfirmbox(false);
        }, 4000);
      })
      .catch(err => {
        setLoading(false);
        setConfirmbox(true);
        setMsg({ status: false, message: err.response.data.msg });
        setTimeout(() => {
          setConfirmbox(false);
        }, 6000)
      })
    }
    else{
      setLoading(false)
      setConfirmbox(true);
      setMsg({ status: false, message: "The total of performance reward weightage must be 100" });
      setTimeout(() => {
        setConfirmbox(false);
      }, 4000);
    }
  }

  const countTotalOfGradeWeightage = () => {
    const len = Object.values(confirmationGradeWeightage).length
    return Object.values(confirmationGradeWeightage).slice(0, len).reduce((sum, val) => sum + val, 0)
  }

  useEffect(()=>{
    setSaveWeightageBtn(countTotalOfGradeWeightage() === 100)
  }, [confirmationGradeWeightage])
  return (
    <>
      <div className="px-3 py-1 border-bottom d-flex justify-content-between align-items-center">
        {loading && (
          <div className="formloader">
            <div className="row text-center">
              <div className="col-12">
                <img src={formloader} alt="" height="200" />
              </div>
              <div className="col-12 text-white h4">
                Updating...
              </div>
            </div>
          </div>
        )}
        <div className="d-flex justify-content-between">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb mb-0 py-2">
              <li
                className={"breadcrumb-item text-primary"}
                style={{ cursor: "pointer" }}
                onClick={() => {
                  navigate("/admin/batch-management/manage");
                }}
              >
                Batch
              </li>
              <li className={"breadcrumb-item active"}>
                Update Skill Days And Weightage
              </li>
            </ol>
          </nav>
        </div>
      </div>
      {confirmbox && (
        <div className="formloade conatiner mx-5 mt-3">
          <div className="row justify-content-center text-center">
            <div className="col-6 w-100 confirmbox">
              <div
                className={`alert ${
                  Msg.status ? "alert-success" : "alert-danger"
                }`}
              >
                {Msg.message}
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="p-2 px-3 mx-2">
        {!!state && (
          <div>
            <h4>Batch Name :- <span className="text-primary">{state.name}</span></h4>
          </div>
        )}
        <div className="mt-4 batchName">
          <ul class="nav nav-tabs justify-content-center">
            <li class="nav-item">
              <a class="nav-link active" data-bs-toggle="tab" href="#qa-content">
              <i class="fa-solid fa-scale-unbalanced-flip me-2"></i>
                Weightage
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" data-bs-toggle="tab" href="#qa-video">
              <i class="fa-solid fa-calendar-days me-2"></i>
                Skill Duration
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" data-bs-toggle="tab" href="#qa-confirmationGrade">
              <i class="fa-solid fa-graduation-cap me-2"></i>
                Confirmation Grade
              </a>
            </li>
          </ul>
          <div class="tab-content pt-3">
            <div id="qa-content" class="tab-pane fade in active show">
            {
              skills?.length > 0 && 
                <div>
                  <div className="d-flex justify-content-between gradeNumber position-relative mt-2 mb-3">
                    <div className="d-flex align-items-center">
                      <h5 htmlFor="performanceReward" className="m-0">Performance Rewards : </h5>
                      <div className="form-check form-check-inline ms-3 align-items-center">
                        <input
                          className="form-check-input"
                          type="radio"
                          id="allRewards"
                          checked={performanceReward === ""}
                          value=""
                          onClick={(e) =>{ setPerformanceReward(e.target.value)}}
                        />
                        <label htmlFor="allRewards" style={{ cursor: "pointer"}}>All</label>
                      </div>
                      <div className="form-check form-check-inline align-items-center">
                        <input
                          className="form-check-input"
                          type="radio"
                          id="performanceReward1"
                          checked={performanceReward === 1}
                          value="1"
                          onClick={(e) => setPerformanceReward(+e.target.value)}
                        />
                        <label htmlFor="performanceReward1" style={{ cursor: "pointer"}}>
                          <i class="fi fi-rr-first-award fa-lg"></i>({countPerformanceWeightage(1)})
                        </label>
                      </div>
                      <div className="form-check form-check-inline align-items-center">
                        <input
                          className="form-check-input"
                          type="radio"
                          id="performanceReward2"
                          checked={performanceReward === 2}
                          value="2"
                          onClick={(e) => setPerformanceReward(+e.target.value)}
                        />
                        <label htmlFor="performanceReward2" style={{ cursor: "pointer"}}>
                          <i class="fi fi-rr-second-award fa-lg"></i>({countPerformanceWeightage(2)})
                        </label>
                      </div>
                      <div className="form-check form-check-inline align-items-center">
                        <input
                          className="form-check-input"
                          type="radio"
                          id="performanceReward3"
                          checked={performanceReward === 3}
                          value="3"
                          onClick={(e) => setPerformanceReward(+e.target.value)}
                        />
                        <label htmlFor="performanceReward3" style={{ cursor: "pointer"}}>
                          <i class="fi fi-rr-third-award fa-lg"></i>({countPerformanceWeightage(3)})
                        </label>
                      </div>
                    </div>
                    {
                      performanceReward !== '' &&
                      <button className="btn btn-success" onClick={handleUpdatePerformance}>Save Reward</button>
                    }
                  </div>
                  <div className="table-responsive">
                    <table className="table mt-2">
                      <thead>
                        <tr>
                          <th valign="middle" scope="col" className="col-md-1 ps-1  bg-body-secondary">#</th>
                          <th valign="middle" scope="col" className="col-md-4 bg-body-secondary">
                            Skill Name
                          </th>
                          <th valign="middle" scope="col" className="col-md-4 text-center bg-body-secondary">
                            Weightage (100%)
                          </th>
                          <th valign="middle" scope="col" className="col-md-3 text-center bg-body-secondary">
                            Performance Reward
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {skills.map((item, index) => {
                          return (
                            <tr className={item.performanceReward === performanceReward && "bg-primary-subtle"}>
                              <td valign="middle">{index + 1} </td>
                              <td valign="middle" className="ps-1">
                                <div className="d-flex">
                                  <div>
                                    {item.label} -
                                  </div>
                                  <span className="text-success ms-1">
                                    Modules ({item.days})
                                  </span>
                                </div>
                              </td>
                              <td valign="middle" className="text-center">
                                <input
                                className="form-control"
                                  placeholder="Enter weightage"
                                  type="number"
                                  value={item.weightage}
                                  disabled={performanceReward === ""}
                                  onChange={(e) => {
                                    handleWeightage(e, item.id);
                                  }}
                                ></input>
                              </td>
                              <td valign="middle" className={`text-center`}>
                                { item?.performanceReward ? ("Reward "+item.performanceReward) : "-"}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              }
            </div>
            <div id="qa-video" class="tab-pane fade">
            {
              skills?.length > 0 && 
                <div>
                  <div className="table-responsive">
                    <table className="table mt-2">
                      <thead>
                        <tr>
                          <th valign="middle" scope="col" className="col-md-1 ps-1 bg-body-secondary">#</th>
                          <th valign="middle" scope="col" className="col-md-4 bg-body-secondary">
                            Skill Name
                          </th>
                          <th valign="middle" scope="col" className="col-md-4 text-center bg-body-secondary">
                            Days
                          </th>
                          <th valign="middle" scope="col" className="col-md-3 text-center bg-body-secondary">
                            Handle
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {skills.map((item, index) => {
                          return (
                            <tr>
                              <td valign="middle">{index + 1} </td>
                              <td valign="middle" className="ps-1">
                                <div className="d-flex">
                                  <div>
                                    {item.label} -
                                  </div>
                                  <span className="text-success ms-1">
                                    Modules ({item.days})
                                  </span>
                                </div>
                              </td>
                              <td valign="middle" className="text-center ps-1">
                                <input
                                className="form-control"
                                  placeholder="Enter days"
                                  type="number"
                                  value={item.days}
                                  onChange={(e) => {
                                    handleDays(e, item.id);
                                  }}
                                ></input>
                                {item.daysError?.length > 0 && (
                                  <div>
                                    {" "}
                                    <span className="text-danger my-2">
                                      {item.daysError}
                                    </span>
                                  </div>
                                )}
                              </td>
                              <td valign="middle" className="text-center">
                                <button
                                  type="button"
                                  disabled={item.days  ? false : true}
                                  className="btn btn-sm mt-1 ms-1 btn-success"
                                  onClick={() => {
                                    setLoading(true)
                                    handleUpdateSkill(item)
                                    }}
                                >
                                  <VscCheck />
                                </button>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              }
            </div>
            <div id="qa-confirmationGrade" className="tab-pane fade">
              <div class="d-flex justify-content-center">
                <div className="fw-medium shadow-sm p-3 mb-5 bg-body-tertiary rounded pe-4">
                  <table cellPadding="3px">
                    <tr>
                      <td>Reward &#8544;</td>
                      <td>
                        <input 
                          type="number" 
                          className="form-control ms-3" 
                          value={confirmationGradeWeightage.performanceReward1Weightage} 
                          placeholder="Rewar 1 Weightage"
                          onChange={(e) => {
                            const val = +e.target.value;
                            if(val>0 && val<100){
                              setConfirmationGradeWeightage(rewards => ({...rewards, performanceReward1Weightage: val}))
                            }
                            else{
                              setConfirmationGradeWeightage(rewards => ({...rewards, performanceReward1Weightage: 0}))
                            }
                          }} />
                      </td>
                    </tr>
                    <tr>
                      <td>Reward &#8544;&#8544;</td>
                      <td>
                        <input 
                          type="number" 
                          className="form-control ms-3" 
                          value={confirmationGradeWeightage.performanceReward2Weightage} 
                          placeholder="Rewar 2 Weightage"
                          onChange={(e) => {
                            const val = +e.target.value;
                            if(val>0 && val<100){
                              setConfirmationGradeWeightage(rewards => ({...rewards, performanceReward2Weightage: val}))
                            }
                            else{
                              setConfirmationGradeWeightage(rewards => ({...rewards, performanceReward2Weightage: 0}))
                            }
                          }} />
                      </td>
                    </tr>
                    <tr>
                      <td>Reward &#8544;&#8544;&#8544;</td>
                      <td>
                        <input 
                          type="number" 
                          className="form-control ms-3" 
                          value={confirmationGradeWeightage.performanceReward3Weightage} 
                          placeholder="Rewar 3 Weightage"
                          onChange={(e) => {
                            const val = +e.target.value;
                            if(val>0 && val<100){
                              setConfirmationGradeWeightage(rewards => ({...rewards, performanceReward3Weightage: val}))
                            }
                            else{
                              setConfirmationGradeWeightage(rewards => ({...rewards, performanceReward3Weightage: 0}))
                            }
                          }} />
                      </td>
                    </tr>
                    <tr>
                      <td>10th Marks</td>
                      <td>
                        <input 
                          type="number" 
                          className="form-control ms-3" 
                          value={confirmationGradeWeightage.tenthMarksWeightage} 
                          placeholder="10th Marks Weightage"
                          onChange={(e) => {
                            const val = +e.target.value;
                            if(val>0 && val<100){
                              setConfirmationGradeWeightage(rewards => ({...rewards, tenthMarksWeightage: val}))
                            }
                            else{
                              setConfirmationGradeWeightage(rewards => ({...rewards, tenthMarksWeightage: 0}))
                            }
                          }} />
                      </td>
                    </tr>
                    <tr>
                      <td>12th Marks</td>
                      <td>
                        <input 
                          type="number" 
                          className="form-control ms-3" 
                          value={confirmationGradeWeightage.twelfthMarksWeightage} 
                          placeholder="12th Marks Weightage"
                          onChange={(e) => {
                            const val = +e.target.value;
                            if(val>0 && val<100){
                              setConfirmationGradeWeightage(rewards => ({...rewards, twelfthMarksWeightage: val}))
                            }
                            else{
                              setConfirmationGradeWeightage(rewards => ({...rewards, twelfthMarksWeightage: 0}))
                            }
                          }} />
                      </td>
                    </tr>
                    <tr>
                      <td>Communication</td>
                      <td>
                        <input 
                          type="number" 
                          className="form-control ms-3" 
                          value={confirmationGradeWeightage.communicationWeightage} 
                          placeholder="Communication Weightage"
                          onChange={(e) => {
                            const val = +e.target.value;
                            if(val>0 && val<100){
                              setConfirmationGradeWeightage(rewards => ({...rewards, communicationWeightage: val}))
                            }
                            else{
                              setConfirmationGradeWeightage(rewards => ({...rewards, communicationWeightage: 0}))
                            }
                          }} />
                      </td>
                    </tr>
                    <tr>
                      <td>Mettl Score</td>
                      <td>
                        <input 
                          type="number" 
                          className="form-control ms-3" 
                          value={confirmationGradeWeightage.mettlWeightage} 
                          placeholder="Communication Weightage"
                          onChange={(e) => {
                            const val = +e.target.value;
                            if(val>0 && val<100){
                              setConfirmationGradeWeightage(rewards => ({...rewards, mettlWeightage: val}))
                            }
                            else{
                              setConfirmationGradeWeightage(rewards => ({...rewards, mettlWeightage: 0}))
                            }
                          }} />
                      </td>
                    </tr>
                  </table>
                  <div className="text-danger text-start py-2" style={{fontSize: "1rem"}}>*The Total of all Rewards must be 100.</div>
                  <div class="d-flex">
                    <button 
                      type="button" 
                      class="btn btn-secondary"
                      onClick={()=> {
                        setConfirmationGradeWeightage({
                          performanceReward1Weightage: state?.performanceReward1Weightage || 20,    
                          performanceReward2Weightage: state?.performanceReward2Weightage || 30,   
                          performanceReward3Weightage: state?.performanceReward3Weightage || 50,
                          tenthMarksWeightage: state?.tenthMarksWeightage || 0,
                          twelfthMarksWeightage: state?.twelfthMarksWeightage || 0,
                          communicationWeightage: state?.communicationWeightage || 0,
                          mettlWeightage: state?.mettlWeightage || 0
                        })
                      }}
                      >Reset</button>
                    <button 
                      type="button" 
                      class={saveWeightageBtn ? "btn btn-primary ms-2" : "btn btn-primary ms-2 disabled"}
                      onClick={addRewardWeightage}
                      >Save</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}