import React, { useEffect, useState } from "react";
import { FaPen, FaTimes } from "react-icons/fa";
import { TrophyOutlined } from '@ant-design/icons';
import Pagination from "react-js-pagination";
import { useLocation, useNavigate } from "react-router-dom";
import Select from "react-select";
import formloader from "../../images/formloading.svg";
import BatchManagementServices from "../../services/BatchManagementServices";
import jwt_decode from "jwt-decode";

const token = localStorage.getItem("token");

const dateInDDMMYYY = (date1) => {
  let date = new Date(date1);
  return `${date.getFullYear()}-${date.getMonth() + 1 < 10
    ? `0${date.getMonth() + 1}`
    : `${date.getMonth() + 1}`
    }-${date.getDate() < 10 ? `0${date.getDate()}` : `${date.getDate()}`}`;
};

const isUserAdmin = () => {
  if(!token) return false;
  try {
    return jwt_decode(token).role === "Admin"
  }catch(_err) {
    return false;
  }
}

export default function BatchList() {
  const dates = {
    to: "",
    from: "",
  };
  const navigate = useNavigate();
  const [allBatches, setAllBatches] = useState([]);
  const [Batches, setBatches] = useState(0);
  const [first, setFirst] = useState(false);
  const [currentpage, setcurrentPage] = useState(1);
  const [Options, setOptions] = useState([]);
  const [selectedOptionsAll, setselectedOptionsAll] = useState([]);
  const [loading, setLoading] = useState(false);
  const [dateRange, setDateRange] = useState(dates);
  const [isActiveBatch, setIsActiveBatch] = useState(true);
  const token = localStorage.getItem("token");
  const { state } = useLocation();

  useEffect(() => {
    const CurrentDate = new Date();
    const beforeTenMonth = new Date();
    beforeTenMonth.setMonth(beforeTenMonth.getMonth() - 10);
    setDateRange({
      to: dateInDDMMYYY(CurrentDate),
      from: dateInDDMMYYY(beforeTenMonth),
    });
    batchFilterByActiveBatch(
      selectedOptionsAll,
      isActiveBatch,
      currentpage,
      dateInDDMMYYY(beforeTenMonth),
      dateInDDMMYYY(CurrentDate)
    );
  }, [first]);

  const handleDelete = (e) => {
    var result = window.confirm("Do you really want to delete batch?");
    if (result) {
      BatchManagementServices.deleteOneBatch(e, token)
        .then((res) => {
          setFirst(!first);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const handleEdit = (id) => {
    navigate(`/admin/batch-management/manage/${id}/update`);
  };

  const handleEditSkill = (list) => {
    navigate(`/admin/batch-management/manage/${list._id}/update-skill-days`, {
      state: list,
    });
  };

  const batchFilterByActiveBatch = (
    batchNames,
    isActive,
    page,
    formDate,
    toDate
  ) => {
    setLoading(true);
    let data = { status: isActive, isDelete: false };
    if (formDate && formDate.length > 0 && toDate && toDate.length > 0) {
      data["startDate"] = {
        $gte: new Date(formDate),
        $lte: new Date(toDate),
      };
    }
    if (batchNames?.length > 0) {
      data["name"] = batchNames.map((item) => item?.name);
    }
    setcurrentPage(page);
    BatchManagementServices.GetAllBatchByStatus(token, data, page)
      .then((res) => {
        setLoading(false);
        setBatches(res.data?.allBatch?.length);
        if (batchNames?.length === 0) {
          setOptions(
            res.data?.allBatch?.length > 0 &&
            res.data.allBatch.map((item) => ({
              label: item.name,
              name: item.name,
              value: item.name,
            }))
          );
        }
        setAllBatches(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleIndex = (index) => {
    const finalNumber = currentpage * 10 - 10;
    return finalNumber + index;
  };

  const handlePageChange = (e) => {
    setLoading(true);
    setcurrentPage(e);
    batchFilterByActiveBatch(
      selectedOptionsAll,
      isActiveBatch,
      e,
      dateRange.from,
      dateRange.to
    );
  };

  const handleChange = (selectedOptions, e) => {
    if (selectedOptions.length !== 0) {
      setselectedOptionsAll(selectedOptions);
      batchFilterByActiveBatch(
        selectedOptions,
        isActiveBatch,
        1,
        dateRange.from,
        dateRange.to
      );
    } else {
      setselectedOptionsAll([]);
      setLoading(true);
      batchFilterByActiveBatch(
        selectedOptions,
        isActiveBatch,
        1,
        dateRange.from,
        dateRange.to
      );
    }
  };

  const dateChange = (event) => {
    const { value, name } = event.target;
    setDateRange({ ...dateRange, [name]: value });
    if (name === "from") {
      batchFilterByActiveBatch(
        selectedOptionsAll,
        isActiveBatch,
        1,
        value,
        dateRange.to
      );
    } else {
      batchFilterByActiveBatch(
        selectedOptionsAll,
        isActiveBatch,
        1,
        dateRange.from,
        value
      );
    }
  };

  return (
    <>
      <div className="px-3 py-1 border-bottom d-flex justify-content-between align-items-center">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb mb-0 py-2">
            <li className={"breadcrumb-item active"}>Batch</li>
          </ol>
        </nav>
      </div>
      <div className="container">
        {loading ? (
          <div className="formloader">
            <div className="row text-center">
              <div className="col-12">
                <img src={formloader} alt="" height="100" />
              </div>
              <div className="col-12 text-white h4">Loading Batches...</div>
            </div>
          </div>
        ) : (
          <>
            <h4 className="my-1 px-3">All Batches</h4>
            <div class="row px-3">
              <div class="col-4">
                <Select
                  placeholder="Filter by name ..."
                  isMulti
                  value={selectedOptionsAll}
                  options={Options}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  onChange={(selectedOptions, e) => {
                    handleChange(selectedOptions, e);
                  }}
                // value={filterOptions}
                />
              </div>
              <div className="col d-flex justify-content-end align-items-center mr-3">
                <div className="form-check form-switch">
                  <input
                    className="form-check-input me-2"
                    type="checkbox"
                    id="disableSwitch"
                    checked={isActiveBatch}
                    onChange={(e) => {
                      setIsActiveBatch(e.target.checked);
                      batchFilterByActiveBatch(
                        selectedOptionsAll,
                        e.target.checked,
                        1,
                        dateRange.from,
                        dateRange.to
                      );
                    }}
                  />
                  Active Batch
                </div>
              </div>
              <div class="col d-flex align-items-center">
                <label className="pe-3" for="from">
                  From:{" "}
                </label>
                <input
                  type="date"
                  id="from"
                  name="from"
                  value={dateRange.from}
                  class="form-control"
                  onChange={(e) => dateChange(e)}
                />
              </div>
              <div class="col d-flex align-items-center">
                <label className="pe-3" for="to">
                  To:{" "}
                </label>
                <input
                  type="date"
                  id="to"
                  name="to"
                  value={dateRange.to}
                  class="form-control"
                  onChange={(e) => dateChange(e)}
                />
              </div>
              {dateRange?.from && dateRange.to && (
                <div className="ocl-12 text-end my-2 fw-bold">
                  Clear date filters{" "}
                  <button
                    onClick={() => {
                      setDateRange({ from: "", to: "" });
                      setselectedOptionsAll([]);
                      batchFilterByActiveBatch([], isActiveBatch, 1, "", "");
                    }}
                  >
                    <FaTimes className="color-white text-danger" />
                  </button>
                </div>
              )}
            </div>
            {allBatches.length !== 0 ? (
              <>
                <ListBatches
                  allBatches={allBatches}
                  handleEdit={handleEdit}
                  handleDelete={handleDelete}
                  handleEditSkill={handleEditSkill}
                  handleIndex={handleIndex}
                />
                <div className="d-flex justify-content-center mb-5">
                  <Pagination
                    activePage={Batches <= 10 ? 1 : currentpage}
                    itemClass="page-item"
                    linkClass="page-link"
                    itemsCountPerPage={10}
                    totalItemsCount={Batches}
                    pageRangeDisplayed={5}
                    onChange={(e) => handlePageChange(e)}
                  />
                </div>
              </>
            ) : (
              <p className="p-5 text-center">No Batches Found ...</p>
            )}
          </>
        )}
      </div>
    </>
  );
}

const ListBatches = ({
  handleDelete,
  handleEdit,
  allBatches,
  handleEditSkill,
  handleIndex,
}) => {

  const handleCreateUpadateDate = (date) => {
    const formattedDate = new Date(date);

    // Get date components
    const year = formattedDate.getFullYear();
    const month = (formattedDate.getMonth() + 1).toString().padStart(2, '0');
    const day = formattedDate.getDate().toString().padStart(2, '0');

    // Get time components
    const hours = formattedDate.getHours().toString().padStart(2, '0');
    const minutes = formattedDate.getMinutes().toString().padStart(2, '0');
    const seconds = formattedDate.getSeconds().toString().padStart(2, '0');

    // Combine date and time components
    const formattedDateTime = `${year}-${month}-${day} at ${hours}:${minutes}:${seconds}`;

    return formattedDateTime;
  };

  return (
    <div className="container p-2 px-3">
      <table className="table mt-4">
        <thead className="fw-bold">
          <th scope="col">#</th>
          <th scope="col">Batch Name</th>
          <th scope="col" className="col-lg-6">
            Skills
          </th>
          <th>Created By</th>
          <th>Updatet By</th>
          <th scope="col">Start Day</th>
          <th scope="col">Total Days</th>
          <th scope="col" className="text-center">
            Handle
          </th>
        </thead>
        <tbody>
          {allBatches.map((item, index) => {
            return (
              <tr>
                <td>{handleIndex(index + 1)}</td>
                <td className="fw-bold">{item.name}</td>
                <td className="">
                  {item?.skills?.map((i) => i?.skillId?.name).join(", ")}
                </td>
                {item.createdBy && item.createdBy['username'] ? (<td>{item.createdBy['username']} on {handleCreateUpadateDate(item.createdAt)}</td>) : (<td></td>)}
                {item.updatedBy && item.updatedBy['username'] ? (<td>{item.updatedBy['username']} on {handleCreateUpadateDate(item.updatedAt)}
                </td>) : (<td></td>)}

                <td>{dateInDDMMYYY(item.startDate)}</td>
                <td>{item.days}</td>
                <td>
                  <div className="d-flex">
                    {isUserAdmin() &&
                    (
                      <div>
                        <button
                          className="btn btn-primary px-2 py-1 mx-1 fs-8"
                          onClick={() => {
                            handleEditSkill(item);
                          }}
                        >
                          <i class="fa-solid fa-award"></i>
                        </button>
                      </div>
                    )}

                    <div>
                      <button
                        className="btn btn-warning px-2 py-1 mx-1 fs-8"
                        onClick={() => {
                          handleEdit(item._id);
                        }}
                      >
                        <FaPen className="color-white text-white" />
                      </button>
                    </div>

                    {isUserAdmin() &&
                    (
                    <div>
                      <button
                        className="btn btn-danger px-2 py-1 mx-1"
                        id={item._id}
                        onClick={() => {
                          handleDelete(item);
                        }}
                      >
                        <FaTimes className="color-white text-white" />
                      </button>
                    </div>
                    )}
                  </div>
                </td>

              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};
