import React, { useState, useEffect } from "react";
import CourseService from "../../services/CourseService";
import formloader from "../../images/formloading.svg";
import { useNavigate } from "react-router-dom";

export default function GenerateMCQXlsx() {
  const [mcqRequirements, setMCQRequirements] = useState({
    subCource: "",
    difficulty: "",
    questionType: "theory",
    questionTopic: "",
    questionCount: 1,
    existingQustions: [],
  });

  const [scnames, setSCnames] = useState([]);
  const [loading, setLoading] = useState(false);
  const [confirmBox, setConfirmBox] = useState(false);
  const [msg, setMsg] = useState({ success: false, message: "" });
  const navigate = useNavigate();
  const css = `
    .ck-editor__editable {
        min-height: 100px !important;
    }
  `;
  useEffect(() => {
    if (localStorage.getItem("token")) {
      CourseService.getSubCourses()
        .then((res) => {
          if (res.data) {
            setSCnames(res.data);
          }
        })
        .catch((ex) => console.log(ex));
    } else {
      navigate("/login");
    }
  }, []);
  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/login");
    } 
    else {
      if(mcqRequirements.subCource.length && mcqRequirements.difficulty.length && mcqRequirements.questionType.length && mcqRequirements.questionCount)
      {
        const scname = scnames.filter(scname => scname._id === mcqRequirements.subCource)[0].name
        setLoading(true)
        CourseService.getGeneratedMCQs({
          skills: scname,
          topic: mcqRequirements.questionTopic,
          qtype : mcqRequirements.questionType,
          difficultylevel: mcqRequirements.difficulty,
          noofquestion: mcqRequirements.questionCount,
          question_data: mcqRequirements.existingQustions,
        }, token)
        .then(resp => {
          const url = window.URL.createObjectURL(new Blob([resp.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }));
          const link = document.createElement('a');
          const fileName =`${scname}_questions.xlsx`
          link.href = url;
          link.download = fileName;
          link.click();
          setLoading(false)
          URL.revokeObjectURL(url);
          handleMessage("Question genereted!!", true, true)
        })
        .catch(err => {
          setLoading(false)
          handleMessage(err["response"]? err["response"].statusText :"Internal server error", false)
        })
      }
      else{
        const msg = `${!mcqRequirements.subCource.length ? "Sub Course," : ""}
        ${!mcqRequirements.difficulty.length ? "Difficulty," : ""}
        ${!mcqRequirements.questionType.length ? "Question type," : ""}
        ${!mcqRequirements.questionCount ? "Qustion Count," : ""}`.trim().slice(0,-1);
        handleMessage(
          `Please verify your ${msg} field`,
          false
        );
      }
    }
  };

  const handleMessage = (message, status, magtime = false) => {
    window.scroll(0, 0)
    setConfirmBox(true)
    setMsg({ message: message, success: status })
    if (magtime) {
      setTimeout(() => setConfirmBox(false), 5000)
    }
  }
const handleSubCourse = (e) => {
    setMCQRequirements((d) => ({ ...d, subCource: e.target.value }))
    CourseService.getMCQsBySubCourseId(e.target.value, localStorage.getItem("token"))
    .then(resp => {
      setMCQRequirements(data => ({
        ...data,
        existingQustions: JSON.stringify(resp.data.data)
      }))      
    })
}

  return (
    <div className="container bg-white p-4">
      <style>{css}</style>
      {loading && (
        <div className="formloader">
          <div className="row text-center">
            <div className="col-12">
              <img src={formloader} alt="" height="100" />
            </div>
            <div className="col-12 text-white h4">
              Generating MCQs.....
            </div>
          </div>
        </div>
      )}
      {confirmBox && (
        <div className="formloade">
          <div className="row text-center">
            <div className="col-6 w-100 confirmbox">
              <div
                className={`alert ${msg.success ? "alert-success" : "alert-danger"
                  }`}
              >
                {msg.message}
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="form px-lg-5">
        <h5 className="color-dgreen text-uppercase text-center heading">
          Generate MCQs
        </h5>
        <form
          onSubmit={handleSubmit}
          className="px-lg-5 py-3"
          method="post"
          id="myform"
        >
          <div className="col position-relative mb-3">
            <label htmlFor="cname" className="form-label">
              SubCourse Name
            </label>
            <select
              className="form-select"
              id="scname"
              name="scname"
              value={mcqRequirements.subCource}
              onChange={handleSubCourse}
            >
              <option value="">select subcourse</option>
              {scnames.map((item, index) => {
                return (
                  <option value={item._id} name={item.name} key={index}>
                    {item.name}
                  </option>
                );
              })}
            </select>
          </div>
              <div className="col position-relative mb-3">
                <label htmlFor="difficulty" className="form-label">
                  Difficulty
                </label>
                <select
                  className="form-select"
                  id="difficulty"
                  value={mcqRequirements.difficulty}
                  name="difficulty"
                  onChange={(e) => setMCQRequirements((d) => ({
                      ...d,
                      difficulty: e.target.value,
                    }))
                  }
                >
                  <option value="">select type</option>
                  <option value="easy">Easy</option>
                  <option value="medium">Medium</option>
                  <option value="hard">Hard</option>
                </select>
              </div>
              <div className="col position-relative mb-3">
                <label htmlFor="question-type" className="form-label me-2">
                  Question Type: 
                </label>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="quetionType"
                    checked={mcqRequirements.questionType === "theory"}
                    value="theory"
                    onClick={() =>
                      setMCQRequirements((d) => ({
                        ...d,
                        questionType: "theory",
                      }))
                    }
                  />
                  <label className="form-check-label" htmlFor="inlineRadio1">
                    Theory
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="quetionType"
                    checked={mcqRequirements.questionType === "code"}
                    value="code"
                    onClick={() =>
                      setMCQRequirements((d) => ({
                        ...d,
                        questionType: "code",
                      }))
                    }
                  />
                  <label className="form-check-label" htmlFor="inlineRadio2">
                    Code
                  </label>
                </div>
              </div>
              <div className="col position-relative mb-3">
                  <label htmlFor="exampleInputPassword1" className="form-label">
                    Question Topic
                  </label>
                <div className="row">
                  <div
                    className={`col col-12`}
                  >
                      <div className="row">
                        <div className="col col-12">
                          <input
                            value={mcqRequirements.questionTopic}
                            onChange={(e) => {
                                setMCQRequirements(data => ({
                                ...data,
                                questionTopic: e.target.value,
                              }));
                            }}
                            type="text"
                            class="form-control mt-1"
                            placeholder="Add sub topic name"
                          />
                        </div>
                      </div>
                  </div>
                </div>
              </div>
              <div className="col position-relative mb-3">
                <label htmlFor="cname" className="form-label">
                  Question Count
                </label>
                <input
                  className="form-control"
                  name="questionCount"
                  type="number"
                  min={1}
                  max={45}
                  inputmode="numeric"
                  placeholder="Enter question count (ex : 5,10,15)"
                  value={mcqRequirements.questionCount}
                  onChange={(e) =>
                    setMCQRequirements((d) => ({
                      ...d,
                      questionCount: e.target.value,
                    }))
                  }
                />
              </div>
              <div className="mb-3">
                <label htmlFor="xlsx" className="form-label">
                  Existing Questions
                </label>
                <textarea 
                    onChange={(e) =>
                      setMCQRequirements((d) => ({
                        ...d,
                        existingQustions: e.target.value,
                      })) 
                    }
                    value={mcqRequirements.existingQustions}
                    className="form-control border-2"
                    placeholder="Questions"
                    style={{
                        fontSize: "0.9rem",
                        background: "none",
                        boxShadow: "none",
                    }}
                  >
                </textarea>
              </div>

          <button type="submit" className="btn btn-submit">
            Add Questions
          </button>
          <button
            type="reset"
            onClick={(e) => {
              setMCQRequirements({
                subCource: "",
                difficulty: "",
                questionType: "theory",
                questionTopic: "",
                questionCount: 1,
                existingQustions: [],
              });
              window.scrollTo(0, 0);
            }}
            className="btn btn-secondary ms-3"
          >
            Reset
          </button>
        </form>
      </div>
    </div>
  );
}
