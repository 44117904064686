import React, { useEffect, useState } from "react";
import LoginService from "../../services/LoginService";
import BatchService from "../../services/BatchManagementServices";
import CourseService from "../../services/CourseService";
import { useLocation, useNavigate } from "react-router-dom";
import Select from "react-select";

const UpdateUser = () => {
  const [loading, setLoading] = useState(false);
  const { state } = useLocation();

  const [upUser, setUpUser] = useState({
    _id: 0,
    username: "",
    email: "",
    password: "",
    role: "",
    status: "offline",
    trainer: "",
    courses: [],
    empId: "",
    approved: false,
    deactivate: false,
    tenthMarks: null,
    twelfthMarks: null,
    mettlScore: null,
    sonarProject: false,
    hostId: "",
    fname: "",
    lname: "",
    mname: "",
    githubUserName: "",
  });
  const [upUserErrors, setUpUserErrors] = useState({
    username: "",
    email: "",
    tenthMarks: "",
    twelfthMarks: "",
    fname: "",
    lname: "",
    mname: "",
    githubUserName: "",
  });
  const [trainers, setTrainers] = useState([]);
  const [courses, setCourses] = useState([]);
  const [msg, setMsg] = useState({ success: true, message: "" });
  const [confirmBox, setConfirmBox] = useState(false);
  const [usersBatches, setUsersBatches] = useState([]);
  const [batchOption, setBatchOption] = useState([]);
  const [batches, setBatches] = useState([]);

  const checkErrors = () => {
    let value = true;
    const values = Object.keys(upUserErrors);
    for (let property of values) {
      if (upUserErrors[property] !== "") {
        value = false;
      }
    }
    return value;
  };

  const findUserBatchs = (userId) => {
    let batchData = [];
    const data = batches.find((item) => {
      if (item.users.find((u) => u._id === userId)) {
        batchData.push({ value: item._id, label: item.name });
      }
    });
    return batchData;
  };

  const token = localStorage.getItem("token");

  useEffect(() => {
    BatchService.GetAllBatch(token)
      .then((res) => {
        let batchList = [];
        for (let data of res.data.data) {
          const batchData = {
            value: data._id,
            label: data.name,
          };
          batchList.push(batchData);
        }
        setBatches(res.data.data);
        let batchData = [];
        res.data.data.find((item) => {
          if (item.users.find((u) => u._id === state._id)) {
            batchData.push({ value: item._id, label: item.name });
          }
        });

        setUsersBatches(batchData);
        setBatchOption([...batchList]);
      })
      .catch((ex) => console.log(ex));
  }, [confirmBox]);

  useEffect(() => {
    if (!state) return;
    setUpUser({
      _id: state._id,
      username: state.username,
      email: state.email,
      password: state.password,
      role: state.role,
      status: state.status,
      trainer: state.trainer,
      courses: state.courses,
      empId: state.empId,
      approved: state.approved,
      deactivate: state.deactivate,
      tenthMarks: state.tenthMarks,
      twelfthMarks: state.twelfthMarks,
      mettlScore: state.mettlScore || null,
      sonarProject: state.sonarProject,
      hostId: state.hostId,
      fname: state.fname,
      lname: state.lname,
      mname: state.mname,
      githubUserName: state.githubUserName,
    });
    setUsersBatches(findUserBatchs(state._id));
  }, []);

  useEffect(() => {
    setLoading(true);
    CourseService.getSubCourses()
      .then((res) => {
        if (res.data.length > 0) {
          setCourses(res.data);
        }
      })
      .catch((ex) => console.log(ex));
    LoginService.getTrainers(token)
      .then((res) => {
        if (res.data.length > 0) {
          const users = res.data.filter((item) => item.role === "Trainer");
          setTrainers(users);
        }
      })
      .catch((ex) => console.log(ex));
    setLoading(false);
  }, [confirmBox, upUser.role]);

  const handleRoleChange = (e) => {
    setLoading(true);

    if (e.target.value === "Student") {
      LoginService.getTrainers(token)
        .then((res) => {
          if (res.data?.length > 0) {
            const users = res.data.filter((item) => item.role === "Trainer");
            setTrainers(users);
          }
        })
        .catch((ex) => console.log(ex));
    }
    if (e.target.value !== "Admin") {
      CourseService.getCourses()
        .then((res) => {
          if (res.data.length > 0) {
            setCourses(res.data);
          }
        })
        .catch((ex) => console.log(ex));
    }
    setLoading(false);
  };

  const handleOnChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    switch (name) {
      case "username":
        if (value.length < 3 || value.length > 40) {
          setUpUser({ ...upUser, username: value });
          setUpUserErrors({
            ...upUserErrors,
            username:
              "userName length should be minimum 3 and maximum 40 required",
          });
        } else {
          setUpUser({ ...upUser, username: value });
          setUpUserErrors({
            ...upUserErrors,
            username: "",
          });
        }
        break;
      case "email":
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value)) {
          setUpUser({ ...upUser, email: value });
          setUpUserErrors({
            ...upUserErrors,
            email: "",
          });
        } else {
          setUpUser({ ...upUser, email: value });
          setUpUserErrors({
            ...upUserErrors,
            email: "You have entered an invalid email address!",
          });
        }
        break;
      case "fname":
        if (value.length < 3 || value.length > 40) {
          setUpUser({ ...upUser, fname: value });
          setUpUserErrors({
            ...upUserErrors,
            fname:
              "firstName length should be minimum 3 and maximum 40 required",
          });
        } else {
          setUpUser({ ...upUser, fname: value });
          setUpUserErrors({
            ...upUserErrors,
            fname: "",
          });
        }
        break;
      case "lname":
        if (value.length < 3 || value.length > 40) {
          setUpUser({ ...upUser, lname: value });
          setUpUserErrors({
            ...upUserErrors,
            lname:
              "lastName length should be minimum 3 and maximum 40 required",
          });
        } else {
          setUpUser({ ...upUser, lname: value });
          setUpUserErrors({
            ...upUserErrors,
            lname: "",
          });
        }
        break;
      case "Gitlab_user_name":
        if (value.includes(" ")) {
          setUpUser({ ...upUser, githubUserName: value });
          setUpUserErrors({
            ...upUserErrors,
            githubUserName: "githubUserName Remove White Spaces Required",
          });
        } else {
          setUpUser({ ...upUser, githubUserName: value });
          setUpUserErrors({
            ...upUserErrors,
            githubUserName: "",
          });
        }
        break;
      case "Tenth_Mark":
        if (value > 100) {
          setUpUser({ ...upUser, tenthMarks: value });
          setUpUserErrors({
            ...upUserErrors,
            tenthMarks: "TenthMarks Lessthan 100 Required",
          });
        } else {
          setUpUser({ ...upUser, tenthMarks: value });
          setUpUserErrors({
            ...upUserErrors,
            tenthMarks: "",
          });
        }
        break;
      case "Twelfth_Marks":
        if (value > 100) {
          setUpUser({ ...upUser, twelfthMarks: value });
          setUpUserErrors({
            ...upUserErrors,
            twelfthMarks: "TwelvethMarks Lessthan 100 Required",
          });
        } else {
          setUpUser({ ...upUser, twelfthMarks: value });
          setUpUserErrors({
            ...upUserErrors,
            twelfthMarks: "",
          });
        }
        break;
      default:
        break;
    }
  };

  const handleUserBatch = (e) => {
    setUsersBatches(e);
  };

  const handleUserBatchUpdate = () => {
    BatchService.getUpdateUserBatch(upUser._id, usersBatches, token)
      .then((res) => {
        if (res.data.success) {
          setConfirmBox(!confirmBox);
        }
      })
      .catch((ex) => {
        setMsg({ message: ex.response.data.error, success: false });
        setConfirmBox(true);
      });
  };

  const handleUpdateUser = (e) => {
    const { _id, username, email, role, status, trainer } = upUser;
    e.preventDefault();

    const validError = checkErrors();
    if (_id && username && email && role && status && validError) {
      setLoading(true);
      if (role === "Student") {
        if (trainer?.length > 0) {
          handleUserBatchUpdate();
          console.log("update users :>", upUser);
          LoginService.putUser(upUser, token)
            .then((res) => {
              if (res.data) {
                setMsg({ message: res.data.message, success: true });
                setConfirmBox(true);
              }
            })
            .catch((ex) => {
              setMsg({ message: ex.response.data.error, success: false });
              setConfirmBox(true);
            });
        } else {
          setMsg({
            message: `Please Select ${
              trainer?.length == 0 ? "Minimum 1 Trainer field Required" : ""
            }`,
            success: false,
          });
          window.scrollTo(0, 0);
          setConfirmBox(true);
        }
      }
      if (role === "Trainer" || role === "Admin" || role === "HR") {
        LoginService.putUser(upUser, token)
          .then((res) => {
            if (res.data) {
              setMsg({ message: res.data.message, success: true });
              setConfirmBox(true);
            }
          })
          .catch((ex) => {
            setMsg({ message: ex.response.data.error, success: false });
            setConfirmBox(true);
          });
      }
      document.getElementById("myform").reset();
      setLoading(false);
    } else {
      setMsg({
        message: "Please verify your field",
        success: false,
      });
      window.scrollTo(0, 0);
      setConfirmBox(true);
    }

    {
      setTimeout(() => setConfirmBox(false), 5000);
    }
  };

  const handleCheckChange = (e, id) => {
    const target = e.target;

    let upcourses = upUser.courses;

    if (target.checked) {
      const coursesData = courses.filter((item) => item._id === id);
      upcourses.push(coursesData[0]?._id);
      setUpUser((e) => ({ ...e, courses: upcourses }));
    } else {
      const index = upcourses.findIndex((item) => item === id);
      if (index !== -1) {
        upcourses.splice(index, 1);
      }
      setUpUser((e) => ({ ...e, courses: upcourses }));
    }
  };
  return (
    <>
      {confirmBox && (
        <div className="formloade">
          <div className="row text-center">
            <div className="col-6 w-100 confirmbox">
              <div
                className={`alert ${
                  msg.success ? "alert-success" : "alert-danger"
                }`}
              >
                {msg.message}
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="bg-white m-0 px-3">
        <form
          onSubmit={handleUpdateUser}
          className="px-lg-5 py-5"
          method="post"
          id="myform"
        >
          <div className="row mb-3">
            <div className="col-lg mb-3 mb-lg-0">
              <label htmlFor="username" className="form-label">
                Username
              </label>
              <input
                type="text"
                name="username"
                id="username"
                value={upUser.username}
                className="form-control"
                maxLength={50}
                required
                onChange={handleOnChange}
                // onChange={(e) => {
                //   setUpUser((d) => ({ ...d, username: e.target.value }));
                // }}
              />
              {upUserErrors.username !== "" && (
                <span className="text-danger d-block my-2">
                  {upUserErrors.username}
                </span>
              )}
            </div>
            <div className="col-lg">
              <label htmlFor="email" className="form-label">
                Email
              </label>
              <input
                type="email"
                name="email"
                id="email"
                value={upUser.email}
                className="form-control"
                required
                onChange={handleOnChange}
                // onChange={(e) => {
                //   setUpUser((d) => ({ ...d, email: e.target.value }));
                // }}
              />
              {upUserErrors.email !== "" && (
                <span className="text-danger d-block my-2">
                  {upUserErrors.email}
                </span>
              )}
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-lg mb-3 mb-lg-0">
              <label htmlFor="role" className="form-label">
                User role
              </label>
              <select
                className="form-select"
                value={upUser.role}
                onChange={(e) => {
                  setUpUser((d) => ({ ...d, role: e.target.value }));
                  handleRoleChange(e);
                }}
              >
                <option value="">select role</option>
                <option value="Student">Student</option>
                <option value="Trainer">Trainer</option>
                <option value="Admin">Admin</option>
                <option value="HR">HR</option>
              </select>
            </div>
            <div className="col-lg">
              <label htmlFor="password" className="form-label">
                Password
              </label>
              <input
                type="password"
                name="password"
                id="password"
                className="form-control"
                value={upUser.password}
                onChange={(e) =>
                  setUpUser((d) => ({ ...d, password: e.target.value }))
                }
              />
            </div>
          </div>
          <div className="row">
            <div className="col col-6 my-2">
              <label htmlFor="empId" className="form-label">
                Employee Id
              </label>
              <input
                type="text"
                name="empId"
                id="empId"
                className="form-control"
                onChange={(e) =>
                  setUpUser((d) => ({ ...d, empId: e.target.value }))
                }
                value={upUser.empId}
              />
            </div>
            <div className="col col-6 my-2 my-auto">
              <div className="d-flex">
                <div className="me-4">
                  <div class="form-check form-switch">
                    <label
                      class="form-check-label"
                      for="flexSwitchCheckCheckedDeactivate"
                    >
                      Deactivate
                    </label>
                    <input
                      class="form-check-input"
                      type="checkbox"
                      id="flexSwitchCheckCheckedDeactivate"
                      checked={upUser.deactivate}
                      onChange={(e) =>
                        setUpUser({
                          ...upUser,
                          deactivate: e.target.checked,
                        })
                      }
                      value={upUser.deactivate}
                    />
                  </div>
                </div>
                <div>
                  <div class="form-check form-switch">
                    <label
                      class="form-check-label"
                      for="flexSwitchCheckChecked"
                    >
                      Approved
                    </label>
                    <input
                      class="form-check-input"
                      type="checkbox"
                      id="flexSwitchCheckChecked"
                      checked={upUser.approved}
                      onChange={(e) =>
                        setUpUser({
                          ...upUser,
                          approved: e.target.checked,
                        })
                      }
                      value={upUser.approved}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col col-6 my-2">
              <label htmlFor="fname" className="form-label">
                First name
              </label>
              <input
                type="text"
                name="fname"
                id="fname"
                className="form-control"
                onChange={handleOnChange}
                // onChange={(e) =>
                //   setUpUser((d) => ({ ...d, fname: e.target.value }))
                // }
                value={upUser.fname}
              />
              {upUserErrors.fname !== "" && (
                <span className="text-danger d-block my-2">
                  {upUserErrors.fname}
                </span>
              )}
            </div>
            <div className="col col-6 my-2">
              <label htmlFor="mname" className="form-label">
                Middle name
              </label>
              <input
                type="text"
                name="mname"
                id="mname"
                className="form-control"
                onChange={(e) =>
                  setUpUser((d) => ({ ...d, mname: e.target.value }))
                }
                value={upUser.mname}
              />
            </div>
            <div className="col col-6 my-2">
              <label htmlFor="lname" className="form-label">
                Last name
              </label>
              <input
                type="text"
                name="lname"
                id="lname"
                className="form-control"
                onChange={handleOnChange}
                // onChange={(e) =>
                //   setUpUser((d) => ({ ...d, lname: e.target.value }))
                // }
                value={upUser.lname}
              />
              {upUserErrors.lname !== "" && (
                <span className="text-danger d-block my-2">
                  {upUserErrors.lname}
                </span>
              )}
            </div>
            <div className="col col-6 my-2">
              <label htmlFor="github_user_name" className="form-label">
                Gitlab user name
              </label>
              <input
                type="text"
                name="Gitlab_user_name"
                id="Gitlab_user_name"
                className="form-control"
                onChange={handleOnChange}
                // onChange={(e) =>
                //   setUpUser((d) => ({
                //     ...d,
                //     githubUserName: e.target.value,
                //   }))
                // }
                value={upUser.githubUserName}
              />
              {upUserErrors.githubUserName !== "" && (
                <span className="text-danger d-block my-2">
                  {upUserErrors.githubUserName}
                </span>
              )}
            </div>
            {upUser.role === "Student" && (
              <div className="col col-12 my-2">
                <label htmlFor="Batch_List" className="form-label">
                  Batches
                </label>
                <Select
                  placeholder="Select Batches ..."
                  isMulti
                  options={batchOption}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  onChange={(e) => {
                    handleUserBatch(e);
                  }}
                  value={usersBatches}
                />
              </div>
            )}
            <div className="col col-6 my-2">
              <label htmlFor="host_id" className="form-label">
                Host Id
              </label>
              <input
                type="text"
                name="host_id"
                id="host_id"
                className="form-control"
                onChange={(e) =>
                  setUpUser((d) => ({
                    ...d,
                    hostId: e.target.value,
                  }))
                }
                value={upUser.hostId}
              />
            </div>
            <div className="col col-6 my-2 d-flex align-items-center">
              <div class="h3">Sonar Status:</div>
              <div class="form-check form-switch ms-3">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="disableSwitch"
                  checked={upUser.sonarProject}
                  onChange={(e) =>
                    setUpUser((d) => ({
                      ...d,
                      sonarProject: !upUser.sonarProject,
                    }))
                  }
                />
              </div>
            </div>
            <div className="col col-4 my-2">
              <label htmlFor="Tenth_Mark" className="form-label">
                Tenth Mark
              </label>
              <input
                type="number"
                inputmode="numeric"
                name="Tenth_Mark"
                id="Tenth_Mark"
                className="form-control"
                onChange={handleOnChange}
                // onChange={(e) =>
                //   setUpUser((d) => ({
                //     ...d,
                //     tenthMarks: e.target.value,
                //   }))
                // }
                value={upUser.tenthMarks}
              />
              {upUserErrors.tenthMarks !== "" && (
                <span className="text-danger d-block my-2">
                  {upUserErrors.tenthMarks}
                </span>
              )}
            </div>
            <div className="col col-4 my-2">
              <label htmlFor="Twelfth_Marks" className="form-label">
                Twelveth Marks
              </label>
              <input
                type="number"
                inputmode="numeric"
                name="Twelfth_Marks"
                id="Twelfth_Marks"
                className="form-control"
                onChange={handleOnChange}
                // onChange={(e) =>
                //   setUpUser((d) => ({
                //     ...d,
                //     twelfthMarks: e.target.value,
                //   }))
                // }
                value={upUser.twelfthMarks}
              />
              {upUserErrors.twelfthMarks !== "" && (
                <span className="text-danger d-block my-2">
                  {upUserErrors.twelfthMarks}
                </span>
              )}
            </div>
            {upUser.tenthMarks && upUser.twelfthMarks && (
              <div className="col col-4 my-2">
                <label></label>
                <div>
                  Average Academic Marks :{" "}
                  {(parseInt(upUser.tenthMarks) +
                    parseInt(upUser.twelfthMarks)) /
                    2}
                </div>
              </div>
            )}
          </div>
          <div className="col-lg mb-3">
            <label htmlFor="metal_score" className="form-label">
              Mettl Score
            </label>
            <input
              type="number"
              inputmode="numeric"
              min="0"
              name="metal_score"
              id="metal_score"
              value={upUser.mettlScore}
              className="form-control"
              onChange={(e) =>
                setUpUser((d) => ({ ...d, mettlScore: e.target.value }))
              }
            />
          </div>
          {upUser.role === "Student" ? (
            <div className="mb-3">
              <label htmlFor="trainer" className="form-label">
                Select Trainer
              </label>
              <select
                className="form-select"
                id="trainer"
                name="trainer"
                value={upUser.trainer}
                onChange={(e) => {
                  console.log(e.target.value);
                  setUpUser((d) => ({ ...d, trainer: e.target.value }));
                }}
              >
                <option value="">Select Trainer</option>
                {trainers.map((item, index) => {
                  return (
                    <option value={item._id} key={index}>
                      {item.username}
                    </option>
                  );
                })}
              </select>
            </div>
          ) : null}
          {upUser.role === "Student" || upUser.role === "Trainer" ? (
            <div className="mb-3">
              <label htmlFor="trainer" className="form-label">
                Select Courses
              </label>
              <div className="p-2 border rounded">
                {courses.map((item, index) => {
                  return (
                    <div
                      className="form-check form-check-inline my-1 mx-2"
                      key={index}
                    >
                      <input
                        className="form-check-input"
                        value={item.status}
                        checked={upUser.courses.includes(item._id.toString())}
                        type="checkbox"
                        id={`checkbox${index}`}
                        onChange={(e) => {
                          handleCheckChange(e, item._id);
                        }}
                      />
                      <label
                        className="form-check-label"
                        htmlFor={`checkbox${index}`}
                      >
                        {item.name}
                      </label>
                    </div>
                  );
                })}
              </div>
            </div>
          ) : null}
          <button type="submit" className="btn text-white bg-green mt-4">
            Update User
          </button>
        </form>
      </div>
    </>
  );
};

export default UpdateUser;
